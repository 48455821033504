import React, {useContext, useEffect} from 'react';
import {ProposalContainer} from '../../ProposalTypePage';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {FormattedMessage} from 'react-intl';
import {ButtonContainerInsurance} from '../InsurancePage';
import {ProposalContext} from '../../ProposalForm';
import ButtonWithTooltip from '@scm/components/form/ButtonWithTooltip';
import {Loading} from '@scm/components';
import ServicesContainer from './components/ServicesContainer';
import SecurityContainer from './components/SecurityContainer';
import {ProposalValues} from '../../ProposalValues';
import {shouldValidateServiceAndSecurityName} from './SecurityPage';
import gaEvent from '@scm/components/ga/googleAnalyticsEvent';
import {proposalPublicRedAppFormStepNumberName} from "../../PagesMap";
import {onboardingRedAppProviderLatestStepName} from '@scm/onboarding/joinUs/JoinUsContentBox';
import {buildGaActionLabel} from "@scm/components/ga/actions";

const ServicesAndSecurityPage = ({currentStep}: {currentStep: number}) => {
  const {handleContinue, handleCancel, handleSaveDraft, isValid, setValue, isLoading, isOnboarding} =
    useContext(ProposalContext);

  useEffect(() => {
    setValue('forceRender', true, {shouldDirty: true, shouldValidate: true, shouldTouch: true});
  }, []);

  useEffect(() => {
    if (isOnboarding) {
      setValue(shouldValidateServiceAndSecurityName as keyof ProposalValues, true, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  }, [isOnboarding]);

  const handleSecurityContinue = () => {
    handleContinue(false);
    const sessionStorageKey = isOnboarding ? onboardingRedAppProviderLatestStepName : proposalPublicRedAppFormStepNumberName;
    if ((sessionStorage.getItem(sessionStorageKey) ?? 0) < currentStep) {
      const formType = isOnboarding ? 'ga.tag.partnerNetwork.redAppProvider' : 'ga.tag.adminCentre.redAppProposal';
      gaEvent(buildGaActionLabel('ga.action.proposal.completedServicesAndPricing' , formType, 'ga.tag.redAppType.public'));
      sessionStorage.setItem(sessionStorageKey, currentStep.toString());
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ProposalContainer className="spark-panel spark-pad-l-2">
            <ServicesContainer />
            <SecurityContainer />
          </ProposalContainer>
          <ButtonContainerInsurance className="spark-mar-t-1 spark-flex">
            <Button
              secondary
              size={ButtonSize.SMALL}
              onClick={() => {
                setValue(shouldValidateServiceAndSecurityName as keyof ProposalValues, false, {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true,
                });
                handleCancel();
              }}
              className="spark-mar-r-1"
            >
              <FormattedMessage id="common.backButton" />
            </Button>
            <div className="row spark-mar-r-.5">
              {!isOnboarding && (
                <Button size={ButtonSize.SMALL} onClick={handleSaveDraft} className="spark-mar-r-1" secondary>
                  <FormattedMessage id="proposal.saveDraft" />
                </Button>
              )}
              <ButtonWithTooltip
                handleContinue={handleSecurityContinue}
                isValid={isValid}
                messageId="proposal.requiredFields.message"
              />
            </div>
          </ButtonContainerInsurance>
        </>
      )}
    </>
  );
};

export default ServicesAndSecurityPage;
