import React, {useState} from 'react';
import ContactDetails from './ContactDetails';
import ErrorMessage from '../../components/ErrorMessage';
import {Confirmation} from '@scm/proposal/pages/proposalForm/steps/serviceAndSecurity/ServiceAndSecurityValues';
import {FieldError, useForm, UseFormSetValue} from 'react-hook-form';
import {
  onboardingRedAppProviderLatestStepName,
  onboardingRedAppProviderRedAppTypeName,
  ProviderTypeValue
} from '../../JoinUsContentBox';
import PricingDetails from './PricingDetails';
import RedAppType from './RedAppType';
import TermsAndConditions from '../developerPartner/TermsAndConditions';
import {yupResolver} from '@hookform/resolvers/yup';
import {ProposalContext} from '@scm/proposal/pages/proposalForm/ProposalForm';
import InsurancePage from '@scm/proposal/pages/proposalForm/steps/InsurancePage';
import DefinitionPage from '@scm/proposal/pages/proposalForm/steps/definitionPage/DefinitionPage';
import PricingPage from '@scm/proposal/pages/proposalForm/steps/PricingPage';
import ServicesAndSecurityPage from '@scm/proposal/pages/proposalForm/steps/serviceAndSecurity/ServicesAndSecurityPage';
import ReviewPage from '@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage';
import SecurityPage from '@scm/proposal/pages/proposalForm/steps/serviceAndSecurity/SecurityPage';
import RegionalizationPage from '@scm/proposal/pages/proposalForm/steps/regionalization/RegionalizationPage';
import {proposalMapper} from '@scm/proposal/pages/proposalForm/mappers/proposalMapper';
import {flowDiagramName, ProposalValues} from '@scm/proposal/pages/proposalForm/ProposalValues';
import {initialValues} from '@scm/proposal/pages/proposalForm/proposalConstants';
import {ProposalType} from '@scm/proposal/pages/proposalForm/PagesMap';
import {basicProposalSchema} from '@scm/proposal/schemas/basicProposalSchema';
import OnboardingFieldsReview from './OnboardingFieldsReview';
import {
  Configuration,
  OnboardingsApi,
  OnboardRedAppProviderRequest,
  Proposal,
  ProvisioningType,
  RedAppPricingType,
  RedAppPricingTypePricingTypeEnum,
} from '../../../generated/onboardings';
import {onboardingsApiBaseLink} from '../../../assets/apiBaseLink';
import Summary from './Summary';
import gaEvent from '@scm/components/ga/googleAnalyticsEvent';
import {buildGaActionLabel} from "@scm/components/ga/actions";

export const onboardingPccName = 'pcc';
export const onboardingPccValueName = 'pccValue';
export const onboardingEprName = 'epr';
export const onboardingEprValueName = 'eprValue';
export const redAppPricingTypeName = 'redAppPricingType';
export const redAppPricingValueTypeName = 'redAppPricingTypeValue';

export interface RadioButtonNamesAndValues {
  [onboardingPccName]: Confirmation;
  [onboardingPccValueName]?: string;
  [onboardingEprName]: Confirmation;
  [onboardingEprValueName]?: string;
  [redAppPricingTypeName]: RedAppPricingType;
  [redAppPricingValueTypeName]: string;
}

const postData = async (request: OnboardRedAppProviderRequest) =>
  await new OnboardingsApi(
    new Configuration({
      basePath: onboardingsApiBaseLink,
    })
  ).onboardRedAppProvider(request);

const ERROR_STEP = 100;

const RedAppProviderFormContent = ({
  currentStep,
  setCurrentStep,
  setProviderTypeValue,
  isPublicRedApp,
  setIsPublicRedApp,
}: {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setProviderTypeValue: UseFormSetValue<ProviderTypeValue>;
  isPublicRedApp?: boolean;
  setIsPublicRedApp: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}) => {
  const [request, setRequest] = useState<OnboardRedAppProviderRequest>({
    contactDetails: {
      firstName: '',
      lastName: '',
      titleInCompany: '',
      email: '',
      phone: '',
    },
    companyInformation: {
      name: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
      },
      businessProfile: '',
      website: '',
      numberOfEmployees: null,
      yearsInBusiness: null,
      locationCountry: '',
      locationProvince: '',
    },
    sabreAccountInfo: {
      pcc: '',
      epr: '',
    },
    redAppPricingType: null,
    proposal: {
      name: '',
      provisioningType: ProvisioningType.Public,
      sellerId: '',
      productInfo: {
        productType: 'Red App',
      },
    },
  });

  const {watch: onboardingWatch, setValue: onboardingSetValue} = useForm<RadioButtonNamesAndValues>({mode: 'onChange'});
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);

  const isOnboarding = true;
  const [handleProposalApprove, shouldHandleProposalApprove] = useState(false);
  const [isSmallSpinnerLoading, setIsSmallSpinnerLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    setError,
    formState: {errors, isValid},
  } = useForm<ProposalValues>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(basicProposalSchema),
  });

  const handleContinue = () => {
    setCurrentStep(prevState => prevState + 1);
    window.scrollTo(0, 0);
  };

  const handleCancel = () => {
    setCurrentStep(prevState => prevState - 1);
  };

  const provisioningType = isPublicRedApp ? ProvisioningType.Public : ProvisioningType.Private;

  const onboardingHandleSubmit = async (success?: () => void, failure?: () => void) => {
    const requestProposal = proposalMapper(getValues()) as unknown as Proposal;
    requestProposal.provisioningType = provisioningType;

    const onboardingRequest: OnboardRedAppProviderRequest = {
      ...request,
      flowDiagram: watch(flowDiagramName)[0],
      proposal: requestProposal,
      redAppPricingType: !!onboardingWatch().redAppPricingTypeValue
        ? {
            pricingType: onboardingWatch().redAppPricingTypeValue as RedAppPricingTypePricingTypeEnum,
          }
        : undefined,
      sabreAccountInfo: {
        epr: onboardingWatch()[onboardingEprValueName],
        pcc: onboardingWatch()[onboardingPccValueName],
      },
    };

    try {
      const resp = await postData(onboardingRequest);
      if (!resp.onboardingId || !resp.proposalId) {
        throw new Error('Onboarding id or proposal id incorrect');
      }
      !!success && success();
      setIsSuccess(true);
      handleContinue();
    } catch (_) {
      !!failure && failure();
      setCurrentStep(ERROR_STEP);
    }
    const redAppTypeTag = isPublicRedApp ? 'ga.tag.redAppType.public' : 'ga.tag.redAppType.private';
    gaEvent(buildGaActionLabel('ga.action.onboarding.submittedRedAppProposal', 'ga.tag.partnerNetwork.redAppProvider', redAppTypeTag));
    sessionStorage.setItem(onboardingRedAppProviderLatestStepName, String(0));
    sessionStorage.setItem(onboardingRedAppProviderRedAppTypeName, '');
  };

  const mutualSteps = [
    <ContactDetails
      saveHandler={contactDetails => {
        setRequest((prevState: OnboardRedAppProviderRequest) => ({
          ...prevState,
          ...contactDetails,
        }));
      }}
      initValue={request}
      setCurrentStep={setCurrentStep}
      watch={onboardingWatch}
      setValue={onboardingSetValue}
      setProviderTypeValue={setProviderTypeValue}
      currentStep={currentStep}
    />,
    <TermsAndConditions
      onboardRedAppProviderRequest={request}
      setCurrentStep={setCurrentStep}
      acceptTermsAndConditions={acceptTermsAndConditions}
      setAcceptTermsAndConditions={setAcceptTermsAndConditions}
      isDeveloperPartnerForm={false}
      currentStep={currentStep}
    />,
    <RedAppType
      setCurrentStep={setCurrentStep}
      isPublicRedApp={isPublicRedApp}
      setIsPublicRedApp={setIsPublicRedApp}
      currentStep={currentStep}
    />,
    <PricingDetails
      setCurrentStep={setCurrentStep}
      setValue={onboardingSetValue}
      watch={onboardingWatch}
      isPublicRedApp={isPublicRedApp}
      currentStep={currentStep}
    />,
  ];

  const onboardingPagesMap = {
    [ProposalType['Public Red App']]: {
      pages: [
        ...mutualSteps,
        <InsurancePage currentStep={currentStep} />,
        <DefinitionPage currentStep={currentStep} />,
        <RegionalizationPage currentStep={currentStep} />,
        <PricingPage currentStep={currentStep} />,
        <ServicesAndSecurityPage currentStep={currentStep} />,
        <ReviewPage
          onboardingFieldsReview={
            <OnboardingFieldsReview
              pricingType={onboardingWatch().redAppPricingTypeValue}
              pcc={onboardingWatch()[onboardingPccValueName]}
              epr={onboardingWatch()[onboardingEprValueName]}
            />
          }
          isPublicRedApp={isPublicRedApp}
        />,
        <Summary />,
      ],
    },
    [ProposalType['Private Red App']]: {
      pages: [
        ...mutualSteps,
        <DefinitionPage currentStep={currentStep} />,
        <SecurityPage currentStep={currentStep} hideSaveDraft />,
        <ReviewPage
          onboardingFieldsReview={
            <OnboardingFieldsReview
              pcc={onboardingWatch()[onboardingPccValueName]}
              epr={onboardingWatch()[onboardingEprValueName]}
            />
          }
          isPublicRedApp={isPublicRedApp}
        />,
        <Summary />,
      ],
    },
  };

  return (
    <ProposalContext.Provider
      value={{
        control,
        watch,
        setValue,
        errors: errors as FieldError,
        setError,
        clearErrors,
        isValid,
        handleContinue,
        handleCancel,
        shouldHandleProposalApprove,
        handleProposalApprove,
        isSmallSpinnerLoading,
        setIsSmallSpinnerLoading,
        onboardingHandleSubmit,
        isOnboarding,
        submitRequest: request,
        isLoading: false,
        reset: function () {
          // no content
        },
        handleSaveDraft: function () {
          // no content
        },
        isSuccess,
      }}
    >
      {currentStep && currentStep !== ERROR_STEP ? (
        onboardingPagesMap[isPublicRedApp ? ProposalType['Public Red App'] : ProposalType['Private Red App']].pages[
          currentStep - 1
        ]
      ) : (
        <ErrorMessage />
      )}
    </ProposalContext.Provider>
  );
};

export default RedAppProviderFormContent;
