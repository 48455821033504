import {Middleware, ResponseContext, RequestContext} from '../generated/product';
import {urlBuilder} from '../components/LoginButton';
import {areTermsAndConditionsAccepted, clearTokens, isLoggedIn} from '../utils/authentication';
const redirectToLoginPage: () => void = () =>
  (window.location.href = urlBuilder(window.location.origin + '/admin-centre'));
export const middleware: Middleware[] = [
  {
    pre: async (context: RequestContext): Promise<RequestContext | void> => {
      if (!isLoggedIn()) {
        clearTokens();
        redirectToLoginPage();
        return context;
      } else {
        return context;
      }
    },
  },
  {
    post: async (context: ResponseContext): Promise<Response | void> => {
      const {terms_and_conditions_accepted} = areTermsAndConditionsAccepted();
      if (context.response.status === 401 && terms_and_conditions_accepted) {
        clearTokens();
        redirectToLoginPage();
        return context.response;
      } else {
        return context.response;
      }
    },
  },
];
