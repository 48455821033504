import * as yup from 'yup';
import {
  connectToGdsDescriptionName,
  connectToGdsName,
  developmentTypeName,
  flowDiagramName,
  interactsWithEndTravelerDescriptionName,
  interactsWithEndTravelerName,
  providePnrDocumentationDescriptionName,
  providePnrDocumentationName,
  redAppCategoryName,
  redAppName,
  technologiesDescriptionName,
  technologiesName,
} from '../pages/proposalForm/ProposalValues';
import {
  basicTextFieldMaxLength,
  fieldGroupTest,
  maxArrayLength,
  minArrayLength,
  minDescriptionMessage,
  minNameMessage,
  nameMaxLength,
  nameMinLength,
  requiredArrayMessage,
  requiredItemMessage,
  standardCorrectFieldMessage,
  technologiesTest,
} from './proposalConstants';

import {publicProposalSchema} from './publicProposalSchema';
import {textEditorLengthErrorName} from '../pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';

export const basicProposalSchema = yup
  .object()
  .shape({
    [redAppName]: yup.string().min(nameMinLength, minNameMessage).max(nameMaxLength).required(),
    [textEditorLengthErrorName]: yup.string().max(0),
    [redAppCategoryName]: yup.array().min(minArrayLength, requiredArrayMessage).max(maxArrayLength).required(),
    [flowDiagramName]: yup
      .array()
      .min(minArrayLength, minArrayLength > 1 ? requiredArrayMessage : requiredItemMessage)
      .max(minArrayLength)
      .required(),
    [interactsWithEndTravelerName]: yup.boolean(),
    [interactsWithEndTravelerDescriptionName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(standardCorrectFieldMessage, minDescriptionMessage, fieldGroupTest(interactsWithEndTravelerName)),
    [providePnrDocumentationName]: yup.boolean(),
    [providePnrDocumentationDescriptionName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(standardCorrectFieldMessage, minDescriptionMessage, fieldGroupTest(providePnrDocumentationName)),
    [connectToGdsName]: yup.boolean(),
    [connectToGdsDescriptionName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(standardCorrectFieldMessage, minDescriptionMessage, fieldGroupTest(connectToGdsName)),
    [developmentTypeName]: yup.string().required(),
    [technologiesName]: yup.array().min(minArrayLength, requiredArrayMessage).required(),
    [technologiesDescriptionName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(standardCorrectFieldMessage, minDescriptionMessage, technologiesTest),
  })
  .concat(publicProposalSchema);
