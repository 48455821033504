import React, {ReactElement, useContext, useEffect} from 'react';
import {Controller, useWatch} from 'react-hook-form';
import {Popover, ProgressIndicator, TextInput} from '@sabre/spark-react-core';
import {MessageStatus, ToastType, TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {redAppName} from '../../../ProposalValues';
import {CheckNameRequest, Configuration, ProposalsApi} from '../../../../../generated/proposal';
import {proposalApiBaseLink} from '../../../../../assets/apiBaseLink';
import {openToast} from '@scm/components/messaging/openToast';
import {ProposalContext} from '../../../ProposalForm';

const RedAppName = ({
  firstParagraph,
  secondParagraph,
  thirdParagraph,
  setIsNameValid,
}: {
  firstParagraph: string;
  secondParagraph: string;
  thirdParagraph?: string;
  setIsNameValid: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {formatMessage} = useIntl();
  const {control, errors, clearErrors, setError, isSmallSpinnerLoading, setIsSmallSpinnerLoading} =
    useContext(ProposalContext);
  const nameMaxLength = 50;
  const ariaLevel = 3;
  const namePattern = /^[a-zA-Z0-9 :().,@'-]{3,50}$/;

  const name = useWatch({
    control,
    name: redAppName,
  });

  const showErrorMessage = () => {
    openToast(
      'definition.redAppName.nameVerificationErrorTitle',
      'definition.redAppName.nameVerificationErrorMessage',
      ToastType.WARNING,
      'spark-icon-alert-triangle'
    );
  };

  useEffect(() => {
    if (name.length >= ariaLevel) {
      const isNameValid = namePattern.test(name);
      setIsNameValid(isNameValid);
      setIsSmallSpinnerLoading(true);
      if (isNameValid) {
        const typingDelay = setTimeout(() => {
          const checkNameRequest = {
            name: name,
          };
          fetchNameStatus(checkNameRequest)
            .then(() => {
              setIsNameValid(true);
              clearErrors && clearErrors([redAppName]);
            })
            .catch(err => {
              setIsNameValid(false);
              setCustomError();
              err.response.status !== 400 && showErrorMessage();
            })
            .finally(() => setIsSmallSpinnerLoading(false));
        }, 1500);

        return () => clearTimeout(typingDelay);
      }
      setTimeout(() => {
        setCustomError();
        setIsSmallSpinnerLoading(false);
      }, 100);
    }
  }, [name]);

  const setCustomError = () => {
    setError &&
      setError(redAppName, {
        type: 'custom',
        message: formatMessage({id: 'definition.redAppName.notUniqueOrInvalidName'}),
      });
  };

  const fetchNameStatus = (checkNameRequest: CheckNameRequest) => {
    return new ProposalsApi(
      new Configuration({
        basePath: proposalApiBaseLink,
      })
    ).checkName(checkNameRequest);
  };

  return (
    <div className="col-xs-9 spark-pad-l-0">
      <div className="row spark-align-items-center">
        <div className="col-xs-8">
          <Controller
            name={redAppName}
            control={control}
            render={({field}): ReactElement => (
              <TextInput
                {...field}
                label={
                  formatMessage(
                    {id: 'definition.redAppName.label'},
                    {
                      star: word => <span className="spark-danger">{word}</span>,
                    }
                  ) as string
                }
                placeHolder={formatMessage({id: 'definition.redAppName.placeholder'})}
                characterCount
                maxLength={nameMaxLength}
                status={errors && errors[field.name as keyof typeof errors] ? MessageStatus.ERROR : undefined}
                statusMessage={
                  errors &&
                  errors[field.name as keyof typeof errors] &&
                  (errors[field.name as keyof typeof errors] as {message: string})?.message
                }
              />
            )}
          />
        </div>
        {isSmallSpinnerLoading && <ProgressIndicator type="indeterminate" size="xs" />}
        <Popover
          anchorX={TooltipDirectionX.RIGHT}
          anchorY={TooltipDirectionY.MIDDLE}
          className="col-xs-2"
          closeButton
          toggleEl={
            <button
              type="button"
              aria-label={formatMessage({id: 'definition.popover.ariaLabel'})}
              className="spark-btn--icon spark-icon spark-icon--fill spark-icon-info-circle"
            />
          }
        >
          <h4 aria-level={ariaLevel}>
            <FormattedMessage id="definition.redAppName.title" />
          </h4>
          <PopoverText>
            <FormattedMessage id={firstParagraph} />
          </PopoverText>
          <PopoverText>
            <FormattedMessage id={secondParagraph} />
          </PopoverText>
          {thirdParagraph && (
            <PopoverText>
              <FormattedMessage id={thirdParagraph} />
            </PopoverText>
          )}
        </Popover>
      </div>
    </div>
  );
};

export const PopoverText = styled.p`
  white-space: break-spaces;
`;

export default RedAppName;
