import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {compareDesc} from 'date-fns';
import styled from 'styled-components';
import {TextInput} from '@sabre/spark-react-core';
import {ToastType} from '@sabre/spark-react-core/types';
import {Configuration as ProposalConfiguration, ProposalsApi} from '../generated/proposal';
import {Configuration as ProductConfiguration, ProductApi} from '../generated/product';
import {Configuration, VersionsApi} from '../generated/certification';
import {Configuration as OnboardingsConfiguration, OnboardingsApi} from '../generated/onboardings';
import InlineButton from '../inline-button/InlineButton';
import {AuditLog, ObjectType} from '../generated/audit';
import AuditLogRow from './AuditLogRow';
import {openToast} from '../messaging/openToast';
import {supportRole} from '../utils/common';
import colors from '../assets/colors';
import {deviceWidths} from '../assets/deviceWidth';
import {certificationApiBaseLink, productApiBaseLink, proposalApiBaseLink} from '../assets/api-urls';

const postProposalComment = async (id: string, comment: string) =>
  new ProposalsApi(
    new ProposalConfiguration({
      basePath: proposalApiBaseLink,
      accessToken: getAccessToken() as string,
    })
  ).addProposalComment({id, commentObject: {comment}});

const postProductComment = async (sku: string, comment: string) =>
  new ProductApi(
    new ProductConfiguration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() as string,
    })
  ).addProductComment({sku, commentObject: {comment}});

const postVersionComment = async (versionId: string, comment: string) =>
  new VersionsApi(
    new Configuration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() as string,
    })
  ).addComment({id: versionId, commentObject: {comment}});

const postOnboardingComment = async (onboardingId: string, comment: string) =>
  new OnboardingsApi(
    new OnboardingsConfiguration({
      basePath: proposalApiBaseLink,
      accessToken: getAccessToken() ?? '',
    })
  ).addOnboardingComment({id: onboardingId, commentObject: {comment}});

const AuditLogs = ({
  auditLogs,
  reloadForm,
  objectType,
  id,
  hideCommentInput,
}: {
  auditLogs: AuditLog[];
  reloadForm: () => void;
  objectType: ObjectType;
  id?: string;
  hideCommentInput?: boolean;
}) => {
  const auditLogHeaders = ['date', 'changedBy', 'appliedStatus'];
  const {formatMessage} = useIntl();
  const commentLength = 4000;
  const commentName = 'comment';
  const generateHeaders = () => (
    <thead>
      <tr>
        {auditLogHeaders.map(name => (
          <AuditLogTableHeader key={name}>
            <strong>
              <FormattedMessage id={`audit.header.${name}`} />
            </strong>
          </AuditLogTableHeader>
        ))}
      </tr>
    </thead>
  );

  const generateBody = () =>
    auditLogs
      .sort((a, b) => compareDesc(new Date(a.createdAt as string), new Date(b.createdAt as string)))
      .map((dataItem: AuditLog) => <AuditLogRow key={dataItem.createdAt} log={dataItem} />);

  const [comment, setComment] = useState('');

  const handleAddComment = async () => {
    try {
      if (objectType === ObjectType.Proposal) {
        await postProposalComment(id || '', comment);
      } else if (objectType === ObjectType.Product) {
        await postProductComment(id || '', comment);
      } else if (objectType === ObjectType.Version) {
        await postVersionComment(id || '', comment);
      } else if (objectType === ObjectType.Onboarding) {
        await postOnboardingComment(id || '', comment);
      }

      reloadForm();
    } catch (err) {
      openToast(
        formatMessage({id: 'certify.error.title'}),
        formatMessage({id: 'certify.error.description'}),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      );
    } finally {
      setComment('');
    }
  };

  return (
    <>
      <AuditLogTable className="spark-table">
        <div className="spark-table__scroll">
          <table role="grid" className="spark-mar-b-1">
            {generateHeaders()}
            <tbody>{generateBody()}</tbody>
          </table>
        </div>
      </AuditLogTable>
      {(hideCommentInput === undefined || !hideCommentInput) && (
        <>
          <Row className="spark-mar-t-2 spark-mar-b-2">
            <TextInput
              name={commentName}
              onChange={(evt, value) => setComment(value)}
              value={comment}
              label={formatMessage({id: 'certify.maintenance.comment'})}
              multiLine
              characterCount
              maxLength={commentLength}
            />
          </Row>
          <Row className="spark-mar-t-2 spark-mar-b-2">
            <SubmitButton
              id="certify.certification.comment.label"
              clickHandler={handleAddComment}
              type="button"
              className="neutral spark-mar-r-1"
              disabled={!comment.trim().length}
            />
          </Row>
        </>
      )}
    </>
  );
};

const SubmitButton = styled(InlineButton)`
  color: ${colors.white}!important;
  &[disabled]:hover {
    color: ${colors.black} !important;
  }
`;

const AuditLogTable = styled.section`
  border-top: 2px solid ${colors.grey300};
  ${supportRole ? 'font-size: 1rem' : ''}
`;

const AuditLogTableHeader = styled.th`
  font-size: ${supportRole ? '1rem' : '1.25rem'} !important;
  background-color: ${colors.white} !important;
  color: ${colors.black} !important;
  border: none !important;
  border-bottom: 1px solid ${colors.grey300} !important;
`;

const Row = styled.div`
  display: flex;
  @media (max-width: ${deviceWidths.md}px) {
    flex-wrap: wrap;
  }

  & > p + *:not(a),
  & label {
    width: 100%;
  }

  & textarea {
    height: auto;
  }

  & .row {
    flex-wrap: nowrap;
  }

  & [checked] + span {
    background-color: ${colors.grey700} !important;
    color: ${colors.white} !important;
  }

  & .spark-btn {
    background-color: ${colors.grey700};
    border: none;
  }

  & .spark-btn::before {
    display: none !important;
  }
`;

const ACCESS_TOKEN_KEY = 'accessToken';
export const getAccessToken = () => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  return token ? String(token) : token;
};

export default AuditLogs;
