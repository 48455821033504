export const dateFormat = 'yyyy-MM-dd';
export const dateWithHour = `${dateFormat} HH:mm:ss`;
export const dateWithHourNoSeconds = `${dateFormat} HH:mm`;
export const time = 'T00:00:00Z';
export const textFileType = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
};
export const dateFormatEndWithYear = 'DD-MM-YYYY';

export const redAppSupportEmail = 'scm-support@sabre.com';

export const pdfFileType = {
  'application/pdf': ['.pdf'],
};

export const scribeScriptsType = {
  'application/ssc': ['.ssc'],
};

export const propertiesType = {
  application: ['.properties'],
};

export const imageFiles = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/svg+xml': ['.svg'],
  'image/webp': ['.webp'],
};

export const resourcesFiles = {
  'text/plain': ['.txt', '.mbd'],
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/xml': ['.xml'],
};

export const zipFileType = {
  'application/zip': ['.zip'],
  'application/x-zip-compressed': ['.zip'],
};
export const pfKeysFileType = {
  'application/pfkey': ['.pfkey'],
};
export const fileSize32 = 33554432;

const ROLE_SABRE_ADMIN = 'ROLE_SABRE_ADMIN';
const ROLE_SECURITY_ADMIN = 'ROLE_SECURITY_ADMIN';
const ROLE_FIRST_LEVEL_SUPPORT = 'ROLE_FIRST_LEVEL_SUPPORT';
const ROLE_SECOND_LEVEL_SUPPORT = 'ROLE_SECOND_LEVEL_SUPPORT';
const ROLE_ADP = 'ROLE_ADP';
const ROLE_RED_APP_DEVELOPER = 'ROLE_RED_APP_DEVELOPER';
const ROLE_STANDARD_PRODUCT_DEVELOPER = 'ROLE_STANDARD_PRODUCT_DEVELOPER';
const ROLE_AGENCY_ADMIN = 'ROLE_AGENCY_ADMIN';

export const sabreEmployeeAccessRoles = [
  ROLE_SABRE_ADMIN,
  ROLE_ADP,
  ROLE_FIRST_LEVEL_SUPPORT,
  ROLE_SECOND_LEVEL_SUPPORT,
  ROLE_SECURITY_ADMIN,
];
export const supportRoles = [
  ROLE_SABRE_ADMIN,
  ROLE_FIRST_LEVEL_SUPPORT,
  ROLE_SECOND_LEVEL_SUPPORT,
  ROLE_SECURITY_ADMIN,
];
export const adminRoles = [ROLE_SABRE_ADMIN, ROLE_SECOND_LEVEL_SUPPORT];
export const supportRole = true;
export const isAdminRole = true;
export const productEditingAllowedRoles = [
  ROLE_SABRE_ADMIN,
  ROLE_SECOND_LEVEL_SUPPORT,
  ROLE_RED_APP_DEVELOPER,
  ROLE_STANDARD_PRODUCT_DEVELOPER,
  ROLE_AGENCY_ADMIN,
];
export const standardProductEditingAllowedRoles = [
  ROLE_SABRE_ADMIN,
  ROLE_SECOND_LEVEL_SUPPORT,
  ROLE_STANDARD_PRODUCT_DEVELOPER,
];
export const betaTestersEditingAllowedRoles = [
  ROLE_SABRE_ADMIN,
  ROLE_SECOND_LEVEL_SUPPORT,
  ROLE_RED_APP_DEVELOPER,
  ROLE_AGENCY_ADMIN,
];
export const betaTestersViewAllowedRoles = [
  ROLE_SABRE_ADMIN,
  ROLE_ADP,
  ROLE_FIRST_LEVEL_SUPPORT,
  ROLE_SECOND_LEVEL_SUPPORT,
  ROLE_RED_APP_DEVELOPER,
  ROLE_AGENCY_ADMIN,
];

export const patchReleaseNotesAllowedRoles = [
  ROLE_SABRE_ADMIN,
  ROLE_SECOND_LEVEL_SUPPORT,
  ROLE_RED_APP_DEVELOPER,
  ROLE_AGENCY_ADMIN,
];

export const removeProductAllowedRoles = [
  ROLE_SABRE_ADMIN,
  ROLE_AGENCY_ADMIN,
  ROLE_RED_APP_DEVELOPER,
  ROLE_STANDARD_PRODUCT_DEVELOPER,
];

export const productViewAllowedRoles = [
  ROLE_SABRE_ADMIN,
  ROLE_ADP,
  ROLE_FIRST_LEVEL_SUPPORT,
  ROLE_SECOND_LEVEL_SUPPORT,
  ROLE_RED_APP_DEVELOPER,
  ROLE_STANDARD_PRODUCT_DEVELOPER,
  ROLE_AGENCY_ADMIN,
];

export const productManageAllowedRoles = [ROLE_SABRE_ADMIN, ROLE_SECOND_LEVEL_SUPPORT];

export const versionManageAllowedRoles = [ROLE_SABRE_ADMIN, ROLE_SECOND_LEVEL_SUPPORT, ROLE_SECURITY_ADMIN];

export const CONTENT_TABS_REQUIRED_SINCE_DATE = new Date(2024, 6, 1);

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
