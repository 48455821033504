export const gaActions: Record<string, string> = {
  'ga.tag.partnerNetwork.developerPartner': 'PNDP',
  'ga.tag.partnerNetwork.redAppProvider': 'PNRAP',
  'ga.tag.adminCentre.redAppProposal': 'ACRAP',
  'ga.tag.redAppType.public': 'Pub',
  'ga.tag.redAppType.private': 'Priv',
  'ga.action.onboarding.movedToTandC': 'Moved to T&C step',
  'ga.action.onboarding.movedToReviewAndSubmit': 'Moved to Review&Submit step',
  'ga.action.onboarding.proposalSubmitted': 'Submitted the proposal',
  'ga.action.onboarding.movedToPricing': 'Moved to Pricing step',
  'ga.action.onboarding.movedToPricing.changedRedAppType': 'Moved to Pricing step (changed Red App type)',
  'ga.action.onboarding.movedToRedAppType': 'Moved to Red App Type step',
  'ga.action.onboarding.movedToProposal': 'Moved to Proposal step',
  'ga.action.onboarding.submittedRedAppProposal': 'Submitted the Red App Proposal',
  'ga.action.proposal.acceptedInsuranceRequirements': 'Accepted Insurance Requirements',
  'ga.action.proposal.addedRedAppDefinition': 'Added Red App Definition',
  'ga.action.proposal.completedRegionalization': 'Completed Regionalization Step',
  'ga.action.proposal.completedPricing': 'Completed Pricing Step',
  'ga.action.proposal.completedServicesAndPricing': 'Completed Services&Security Step',
  'ga.action.proposal.completedSecurity': 'Completed Security Step',
  'ga.action.proposal.pickedPublicRedApp': 'Picked Public Red App Type (Proposal)',
  'ga.action.proposal.pickedPrivateRedApp': 'Picked Private Red App Type (Proposal)',
};

export const buildGaActionLabel = (action: string, tag: string, tag2?: string, ): string => {
  let label = `[${gaActions[tag]}`;
  if (tag2) {
    label += `:${gaActions[tag2]}`;
  }
  label += `] ${gaActions[action]}`;
  return label;
};
