import React, {useContext, useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  Configuration as ProposalConfiguration,
  GeneralDevelopmentTypeEnum,
  ProposalsApi,
  ProvisioningType,
} from '../../../../generated/proposal';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import {DataType, ProposalDataItem} from '../ProposalDataItem';
import {
  connectToGdsDescriptionName,
  descriptionName,
  developmentTypeName,
  interactsWithEndTravelerDescriptionName,
  proposalTypeName,
  providePnrDocumentationDescriptionName,
  providerName,
  technologiesName,
} from '../../../proposalForm/ProposalValues';
import {ProposalDataViewContext} from '../ProposalViewDataProvider';
import {ProductCategories} from '@scm/components/assets/productCategories';
import {redAppCategoryName} from '../ProposalDataValues';
import {proposalApiBaseLink} from '../../../../assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {Configuration as SellersConfiguration, SellersApi} from '@scm/sellers/generated/sellers';
import {sellersApiBaseLink} from '@scm/sellers/assets/sellersUrl';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {ToastType} from '@sabre/spark-react-core/types';
import {TextEditor} from '@scm/components';
import {middleware} from '@scm/authentication/middleware/middlewareConfig';
export const textEditorLengthErrorName = 'textEditorLengthError';

const fetchFlowDiagram = async (id: string) =>
  new ProposalsApi(
    new ProposalConfiguration({basePath: proposalApiBaseLink, accessToken: getAccessToken(), middleware})
  ).downloadFlowDiagram({id});

const fetchSeller = async (id: string) =>
  new SellersApi(
    new SellersConfiguration({basePath: sellersApiBaseLink, accessToken: getAccessToken(), middleware})
  ).getSeller({
    id,
  });

const ProposalViewDataDefinition = ({
  proposalName,
  proposalId,
  sellerid,
  submittedBy,
  provisioningType,
  description,
  categories,
  interactionWithEndTravelerComment,
  pnrDocumentationComment,
  connectionToGdsComment,
  developmentType,
  technologies,
  isEditable,
  hasFlowDiagram,
}: {
  proposalName: string;
  proposalId: string;
  sellerid?: string;
  submittedBy?: string;
  provisioningType?: ProvisioningType;
  description?: string;
  categories?: Array<string>;
  interactionWithEndTravelerComment?: string;
  pnrDocumentationComment?: string;
  connectionToGdsComment?: string;
  developmentType?: GeneralDevelopmentTypeEnum;
  technologies?: Array<string>;
  isEditable?: boolean;
  hasFlowDiagram?: boolean;
}) => {
  const {formatMessage} = useIntl();
  const {watch, setValue} = useContext(ProposalDataViewContext);
  const [sellerName, setSellerName] = useState<string>();
  const productDetailsName = 'productDetails.seller';

  function mapDataType(type: string) {
    switch (type) {
      case 'application/msword':
        return '.doc';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '.docx';
      case 'application/pdf':
        return '.pdf';
      default:
        return '';
    }
  }

  function handleDownload() {
    fetchFlowDiagram(proposalId || '')
      .then(flowDiagramBlob => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(flowDiagramBlob);
        downloadLink.download = proposalName + '_FlowDiagram' + mapDataType(flowDiagramBlob.type);
        downloadLink.click();
      })
      .catch(() =>
        openToast(
          createMessageString(formatMessage, 'proposalDetails.flowDiagram', true, true),
          createMessageString(formatMessage, 'proposalDetails.flowDiagram', false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      );
  }

  useEffect(() => {
    if (sellerid) {
      fetchSeller(sellerid)
        .then(seller => setSellerName(seller.name || ''))
        .catch(() =>
          openToast(
            createMessageString(formatMessage, productDetailsName, true, true),
            createMessageString(formatMessage, productDetailsName, false, true),
            ToastType.WARNING,
            'spark-icon-alert-triangle'
          )
        );
    }
  }, [sellerid]);

  const descriptionMaxLength = 3000;

  return (
    <>
      <Separator className="spark-mar-l-2 spark-mar-b-1 spark-mar-t-2 spark-bold spark-large">
        <FormattedMessage id="proposalDetails.definition" />
      </Separator>
      {sellerid && sellerName && (
        <Row className="spark-mar-l-2">
          <Label className="spark-bold">
            <FormattedMessage id="proposalDetails.provider.name" />
          </Label>
          <ProposalDataItem
            dataType={DataType.Text}
            options={{
              name: providerName,
              label: formatMessage({id: 'proposalDetails.provider.name'}),
              placeholder: formatMessage({id: 'proposalDetails.provider.name'}),
              defaultValue: sellerName,
              disabled: true,
            }}
          />
        </Row>
      )}
      {submittedBy && (
        <Row className="spark-mar-l-2">
          <Label className="spark-bold">
            <FormattedMessage id="proposalDetails.seller.name" />
          </Label>
          <ProposalDataItem
            dataType={DataType.Text}
            options={{
              name: providerName,
              label: formatMessage({id: 'proposalDetails.seller.name'}),
              placeholder: formatMessage({id: 'proposalDetails.seller.name'}),
              defaultValue: submittedBy,
              disabled: true,
            }}
          />
        </Row>
      )}
      {provisioningType && (
        <Row className="spark-mar-l-2">
          <Label className="spark-bold">
            <FormattedMessage id="proposalDetails.redAppType" />
          </Label>
          <ProposalDataItem
            dataType={DataType.Select}
            options={{
              name: proposalTypeName,
              label: formatMessage({id: 'proposalDetails.redAppType'}),
              placeholder: formatMessage({id: 'proposalDetails.redAppType'}),
              selectOptions: Object.values(ProvisioningType).map(type => ({value: type, label: type})),
              defaultValue: provisioningType,
              value: provisioningType,
              disabled: true,
            }}
          />
        </Row>
      )}
      {description && (
        <Row className="spark-mar-l-2">
          <Label className="spark-bold">
            <FormattedMessage id="proposalDetails.description" />
          </Label>
          <StyledTextEditor
            editorState={watch(descriptionName) ?? description}
            setValue={setValue}
            valueName={descriptionName}
            maxCharacters={descriptionMaxLength}
            placeholder={formatMessage({id: 'proposalDetails.description'})}
            errorName={textEditorLengthErrorName}
            isRequired
            isProposal
            disabled={!isEditable}
          />
        </Row>
      )}
      {categories && (
        <Row className="spark-mar-l-2">
          <Label className="spark-bold">
            <FormattedMessage id="proposalDetails.categories" />
          </Label>
          <ProposalDataItem
            dataType={DataType.MultiSelect}
            options={{
              name: redAppCategoryName,
              label: formatMessage({id: 'proposalDetails.categories.select'}),
              firstLevelValues: categories,
              selectOptions: Object.values(ProductCategories).map(category => ({value: category, label: category})),
              disabled: !isEditable,
            }}
          />
        </Row>
      )}
      {hasFlowDiagram && (
        <Row className="spark-mar-l-2">
          <Label className="spark-bold">
            <FormattedMessage id="proposalDetails.flowDiagram" />
          </Label>
          <ProposalDataItem
            dataType={DataType.File}
            options={{
              name: proposalName,
              clickHandler: handleDownload,
              disabled: !isEditable,
            }}
          />
        </Row>
      )}
      <Row className="spark-mar-l-2">
        <Label className="spark-bold">
          <FormattedMessage id="proposalDetails.interactionWithEndTraveler" />
        </Label>
        <ProposalDataItem
          dataType={DataType.Radio}
          options={{
            value: watch(interactsWithEndTravelerDescriptionName) ?? !!interactionWithEndTravelerComment,
            name: interactsWithEndTravelerDescriptionName,
            disabled: true,
          }}
        />
      </Row>
      {interactionWithEndTravelerComment && (
        <Row className="spark-mar-l-2">
          <Label className="spark-bold">
            <FormattedMessage id="proposalDetails.interactionWithEndTravelerComment" />
          </Label>
          <ProposalDataItem
            dataType={DataType.TextList}
            options={{
              name: interactsWithEndTravelerDescriptionName,
              label: formatMessage({id: 'proposalDetails.interactionWithEndTravelerComment'}),
              placeholder: formatMessage({id: 'proposalDetails.interactionWithEndTravelerComment'}),
              value: interactionWithEndTravelerComment,
              disabled: true,
            }}
          />
        </Row>
      )}
      <Row className="spark-mar-l-2">
        <Label className="spark-bold">
          <FormattedMessage id="proposalDetails.pnrDocumentation" />
        </Label>
        <ProposalDataItem
          dataType={DataType.Radio}
          options={{
            value: watch(providePnrDocumentationDescriptionName) ?? !!pnrDocumentationComment,
            name: providePnrDocumentationDescriptionName,
            disabled: true,
          }}
        />
      </Row>
      {pnrDocumentationComment && (
        <Row className="spark-mar-l-2">
          <Label className="spark-bold">
            <FormattedMessage id="proposalDetails.pnrDocumentationComment" />
          </Label>
          <ProposalDataItem
            dataType={DataType.TextList}
            options={{
              name: providePnrDocumentationDescriptionName,
              label: formatMessage({id: 'proposalDetails.pnrDocumentationComment'}),
              placeholder: formatMessage({id: 'proposalDetails.pnrDocumentationComment'}),
              value: pnrDocumentationComment,
              disabled: true,
            }}
          />
        </Row>
      )}
      <Row className="spark-mar-l-2">
        <Label className="spark-bold">
          <FormattedMessage id="proposalDetails.connectionToGds" />
        </Label>
        <ProposalDataItem
          dataType={DataType.Radio}
          options={{
            value: watch(connectToGdsDescriptionName) ?? !!connectionToGdsComment,
            name: connectToGdsDescriptionName,
            disabled: true,
          }}
        />
      </Row>
      {connectionToGdsComment && (
        <Row className="spark-mar-l-2">
          <Label className="spark-bold">
            <FormattedMessage id="proposalDetails.connectionToGdsComment" />
          </Label>
          <ProposalDataItem
            dataType={DataType.TextList}
            options={{
              name: connectToGdsDescriptionName,
              label: formatMessage({id: 'proposalDetails.connectionToGdsComment'}),
              placeholder: formatMessage({id: 'proposalDetails.connectionToGdsComment'}),
              value: connectionToGdsComment,
              disabled: true,
            }}
          />
        </Row>
      )}
      {developmentType && (
        <Row className="spark-mar-l-2">
          <Label className="spark-bold">
            <FormattedMessage id="proposalDetails.developmentType" />
          </Label>
          <ProposalDataItem
            dataType={DataType.Select}
            options={{
              defaultValue: developmentType,
              label: formatMessage({id: 'proposalDetails.developmentType'}),
              name: developmentTypeName,
              selectOptions: Object.values(GeneralDevelopmentTypeEnum).map(value => ({value, label: value})),
              disabled: true,
            }}
          />
        </Row>
      )}
      {technologies && (
        <Row className="spark-mar-l-2">
          <Label className="spark-bold">
            <FormattedMessage id="proposalDetails.technologies" />
          </Label>
          <ProposalDataItem
            dataType={DataType.TextList}
            options={{
              label: formatMessage({id: 'proposalDetails.technologies'}),
              name: technologiesName,
              value: technologies.toString(),
              disabled: true,
            }}
          />
        </Row>
      )}
    </>
  );
};

export const Row = styled.div`
  display: flex;
  @media (max-width: ${deviceWidths.md}px) {
    flex-wrap: wrap;
  }

  & > p + *:not(a),
  & label {
    width: 100%;
  }

  & textarea {
    height: auto;
  }

  & .row {
    flex-wrap: nowrap;
  }

  & [checked] + span {
    background-color: ${colors.grey700} !important;
    color: ${colors.white} !important;
  }

  & .spark-btn {
    background-color: ${colors.grey700};
    border: none;
  }

  & .spark-btn::before {
    display: none !important;
  }
`;

export const Label = ({className, children}: {className: string; children: JSX.Element}) => (
  <StyledLabel className={className} data-test-label>
    {children}
  </StyledLabel>
);

const StyledLabel = styled.p`
  width: 30rem;
  min-height: 6rem;
  display: flex;
  flex-grow: 0;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.2rem;
  @media (max-width: ${deviceWidths.md}px) {
    min-height: 0;
    margin-top: 2rem;
    width: 100%;
  }
`;

export const Separator = styled.p`
  border-bottom: 1px solid ${colors.grey150};
  margin-right: 1rem;
  padding-bottom: 1rem;
`;

export const StyledTextEditor = styled(TextEditor).attrs(({disabled}: {disabled: boolean}) => ({
  disabled,
}))`
  div,
  pre {
    border-color: ${props => props.disabled && colors.grey100 + ' !important'};
    border-radius: ${props => props.disabled && '0.25rem'};
  }
  p {
    font-size: 1.33rem;
    background-color: ${props => props.disabled && colors.grey100};
    cursor: ${props => props.disabled && 'not-allowed'};
    color: ${props => props.disabled && colors.disabledTextFieldGrey};
  }
`;

export default ProposalViewDataDefinition;
