import React, {ReactElement, useContext, useEffect, useMemo, useState} from 'react';
import {Controller} from 'react-hook-form';
import {ProposalContext} from './ProposalForm';
import {Button, Message, RadioButton, RadioButtonGroup, SelectInput} from '@sabre/spark-react-core';
import {FormattedMessage, useIntl} from 'react-intl';
import {Star} from '@scm/components';
import {ButtonSize, MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {ProposalType} from './PagesMap';
import styled from 'styled-components';
import {formTitleId, proposalTypeName, providerName} from './ProposalValues';
import {isAgencyAdminRole, isSubmitProposalRole} from '@scm/authentication/utils/authentication';
import {SellersContext} from '@scm/sellers/components/SellersProvider';
import {redAppSupportEmail} from '@scm/components/utils/common';
import HrefElement from '@scm/components/messaging/HrefElement';
import OnboardingInfo from '@scm/components/onboardingInfo/OnboardingInfo';
import ProcessSchemes from '@scm/components/processScheme/schemes';
import ProcessScheme from '@scm/components/processScheme/ProcessScheme';
import colors from '@scm/components/assets/colors';
import gaEvent from '@scm/components/ga/googleAnalyticsEvent';
import {buildGaActionLabel} from "@scm/components/ga/actions";

export interface Seller {
  id: string;
  name: string;
}

const ProposalTypePage = () => {
  const PUBLIC_RED_APP = 'Public Red App';
  const PRIVATE_RED_APP = 'Private Red App';
  const {formatMessage} = useIntl();
  const {watch, control, handleContinue, handleCancel, setValue} = useContext(ProposalContext);
  const proposalType = watch(proposalTypeName);
  const {userSellers} = useContext(SellersContext);
  const [shouldResetSteps, setShouldResetSteps] = useState(false);
  const [initialProposalType, setInitialProposalType] = useState<ProposalType>();
  const [showProcessSchemeModal, setShowProcessSchemeModal] = useState(false);
  const [processScheme, setProcessScheme] = useState(ProcessSchemes.PublicApp);
  const [sellersWithPossibleProposal, setSellersWithPossibleProposal] = useState<Seller[]>([]);

  useEffect(() => {
    setSellersWithPossibleProposal(userSellers.filter(seller => seller.canSubmitProposal));
  }, [userSellers]);

  useEffect(() => {
    setValue(formTitleId, 'proposal.title');
  }, [formTitleId]);

  useEffect(() => {
    setInitialProposalType(proposalType as ProposalType);
  }, []);

  const hasSellers = (numberOfSellers: number) =>
    sellersWithPossibleProposal.length > numberOfSellers &&
    [PUBLIC_RED_APP, PRIVATE_RED_APP].includes(proposalType as string);

  useEffect(() => {
    if (sellersWithPossibleProposal.length === 1) {
      setValue(providerName, sellersWithPossibleProposal[0].id);
    }
  }, [sellersWithPossibleProposal, setValue, providerName]);

  const ProposalTypesMessages = {
    [ProposalType[PUBLIC_RED_APP]]: (
      <>
        <Message
          content=""
          role={MessageRole.STATUS}
          status={MessageStatus.INFO}
          title={formatMessage({id: 'proposal.publicRedApp.message.title'})}
          type={MessageType.CONTAINER}
        />
        <p className="spark-mar-t-2">
          <FormattedMessage
            id="proposal.publicRedApp.message.content.firstLine"
            values={{strong: (word: string) => <strong>{word}</strong>}}
          />
        </p>
        <p className="spark-mar-t-2">
          <FormattedMessage id="proposal.publicRedApp.message.content.secondLine" />
        </p>
        <OnboardingInfo onlyPublic />
        <br />
        <ButtonModal
          className="spark-mar-t-2"
          onClick={event => {
            event.preventDefault();
            setProcessScheme(ProcessSchemes.PublicApp);
            setShowProcessSchemeModal(true);
          }}
        >
          {formatMessage({id: 'processScheme.redApp.button.text'})}
        </ButtonModal>
      </>
    ),
    [ProposalType[PRIVATE_RED_APP]]: (
      <>
        <Message
          content=""
          role={MessageRole.STATUS}
          status={MessageStatus.INFO}
          title={formatMessage({id: 'proposal.privateRedApp.message.title'})}
          type={MessageType.CONTAINER}
        />
        <p className="spark-mar-t-2">
          <FormattedMessage id="proposal.privateRedApp.message.content" />
        </p>
        <OnboardingInfo onlyPrivate />
        <br />
        <ButtonModal
          onClick={event => {
            event.preventDefault();
            setProcessScheme(ProcessSchemes.PrivateApp);
            setShowProcessSchemeModal(true);
          }}
        >
          <FormattedMessage id="processScheme.redApp.button.text" />
        </ButtonModal>
      </>
    ),
    [ProposalType['PF Keys']]: (
      <>
        <Message
          content=""
          role={MessageRole.STATUS}
          status={MessageStatus.INFO}
          title={formatMessage({id: 'proposal.pfKeys.message.title'})}
          type={MessageType.CONTAINER}
        />
        <p className="spark-mar-t-2">
          <FormattedMessage id="proposal.pfKeys.message.content" />
        </p>
        <ButtonModal
          onClick={event => {
            event.preventDefault();
            setProcessScheme(ProcessSchemes.PfKeys);
            setShowProcessSchemeModal(true);
          }}
        >
          <FormattedMessage id="processScheme.pfKeys.button.text" />
        </ButtonModal>
      </>
    ),
    [ProposalType['Scribe Scripts']]: (
      <>
        <Message
          content=""
          role={MessageRole.STATUS}
          status={MessageStatus.INFO}
          title={formatMessage({id: 'proposal.scribeScripts.message.title'})}
          type={MessageType.CONTAINER}
        />
        <p className="spark-mar-t-2">
          <FormattedMessage id="proposal.scribeScripts.message.content" />
        </p>
        <ButtonModal
          onClick={event => {
            event.preventDefault();
            setProcessScheme(ProcessSchemes.Scribes);
            setShowProcessSchemeModal(true);
          }}
        >
          {formatMessage({id: 'processScheme.scribes.button.text'})}
        </ButtonModal>
      </>
    ),
  };

  const initialMessage = (
    <Message
      content={formatMessage({id: 'proposal.proposalType.message.content'})}
      role={MessageRole.STATUS}
      status={MessageStatus.INFO}
      title={formatMessage({id: 'proposal.proposalType.message.title'})}
      type={MessageType.CONTAINER}
    />
  );

  const createMessage = () =>
    ProposalTypesMessages[proposalType as keyof typeof ProposalTypesMessages] ?? initialMessage;

  const proposalTypeFieldsetLabel = 'proposalType';

  const createSelectOptions = () => {
    const selectList = [{id: '', name: ''}, ...sellersWithPossibleProposal];
    return Object.values(selectList).map(value => ({value: value.id, label: value.name || value.id}));
  };

  const handleProposalTypeContinue = () => {
    const action = proposalType === ProposalType['Public Red App'] ? 'ga.action.proposal.pickedPublicRedApp' : 'ga.action.proposal.pickedPrivateRedApp';
    gaEvent(buildGaActionLabel(action ,'ga.tag.adminCentre.redAppProposal'));
    handleContinue(shouldResetSteps);
  };

  const availableButtons = useMemo(() => {
    let buttons =
      !!sellersWithPossibleProposal.length && isSubmitProposalRole()
        ? [ProposalType['Public Red App'], ProposalType['Private Red App']]
        : [];
    if (isAgencyAdminRole()) {
      buttons = [...buttons, ProposalType['PF Keys'], ProposalType['Scribe Scripts']];
    }
    return Object.values(buttons);
  }, [isAgencyAdminRole, sellersWithPossibleProposal]);

  return (
    <>
      <ProposalContainer className="spark-panel spark-pad-2">
        <div className="row">
          <div className="col-xs-5">
            <p className="spark-mar-b-1" id={proposalTypeFieldsetLabel}>
              <FormattedMessage id="proposal.proposalType.label" />
              <Star hasColon />
            </p>
            <Controller
              name={proposalTypeName}
              control={control}
              render={({field: {name, value}}): ReactElement => (
                <RadioButtonGroup
                  name={name}
                  value={value ?? ''}
                  onChange={(_, value) => {
                    const isPublicOrPrivateProposal = value === PUBLIC_RED_APP || value === PRIVATE_RED_APP;
                    setShouldResetSteps(
                      !!initialProposalType && isPublicOrPrivateProposal && initialProposalType !== value
                    );
                    setValue(proposalTypeName, value as ProposalType);
                  }}
                  aria-labelledby={proposalTypeFieldsetLabel}
                >
                  {availableButtons.map(type => (
                    <RadioButton key={type} label={type} value={type} />
                  ))}
                </RadioButtonGroup>
              )}
            />
          </div>
          <div className="col-xs-7">{createMessage()}</div>
        </div>
        {hasSellers(1) && (
          <ProviderContainer className="row">
            <div className="col-xs-5">
              <p className="spark-mar-b-1">
                <FormattedMessage id="proposal.providers.label" />
                <Star hasColon />
              </p>
              <p className="spark-mar-b-1">
                <FormattedMessage id="proposal.providers.message.content" />
              </p>
              <Controller
                name={providerName}
                control={control}
                render={({field: {onChange, onBlur, name, value}}): ReactElement => (
                  <SelectInput
                    label={formatMessage({id: 'proposal.providers.list.label'})}
                    name={name}
                    value={value ?? ''}
                    onBlur={onBlur}
                    onChange={onChange}
                    options={createSelectOptions()}
                  />
                )}
              />
            </div>
          </ProviderContainer>
        )}
        {proposalType && [PUBLIC_RED_APP, PRIVATE_RED_APP].includes(proposalType) && !hasSellers(0) && (
          <Message
            content={
              formatMessage(
                {id: 'proposal.providers.warningMessage.content'},
                {
                  strong: text => <strong>{text}</strong>,
                  email: <HrefElement email={redAppSupportEmail} />,
                }
              ) as string
            }
            role={MessageRole.ALERT}
            status={MessageStatus.WARNING}
            title={formatMessage({id: 'proposal.providers.warningMessage.title'})}
            type={MessageType.CONTAINER}
            className=" spark-mar-t-2 spark-mar-l-1"
          />
        )}
      </ProposalContainer>
      <ButtonContainer className="spark-mar-t-1 spark-flex">
        <Button secondary size={ButtonSize.SMALL} onClick={handleCancel} className="spark-mar-r-1">
          <FormattedMessage id="common.cancelButton" />
        </Button>
        <Button
          size={ButtonSize.SMALL}
          onClick={handleProposalTypeContinue}
          disabled={
            proposalType === undefined ||
            ([PUBLIC_RED_APP, PRIVATE_RED_APP].includes(proposalType) && proposalType && !hasSellers(0)) ||
            (hasSellers(1) && !watch(providerName))
          }
        >
          <FormattedMessage id="common.continueButton" />
        </Button>
      </ButtonContainer>
      <ProcessScheme
        processScheme={processScheme}
        open={showProcessSchemeModal}
        setOpen={setShowProcessSchemeModal}
        isProposal
      />
    </>
  );
};

const ButtonModal = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${colors.sparkBlue};
  cursor: pointer;
  font: inherit;
`;

export const ButtonContainer = styled.div`
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const ProposalContainer = styled.div`
  min-height: 50vh;
`;

export const ProviderContainer = styled.div`
  margin-top: 30px;
`;

export default ProposalTypePage;
