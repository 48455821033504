import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import colors from '../assets/colors';
import {UseFormSetValue} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import {DeltaStatic, Quill, Sources} from 'quill';
import {textUtils} from './textUtils';

export const TextEditor = ({
                             editorState,
                             setValue,
                             valueName,
                             maxCharacters,
                             minCharacters,
                             disabled = false,
                             errorName,
                             isRequired,
                             placeholder,
                             className,
                             isProposal,
                             isSimple = false,
                             alignEnabled,
                             setCountedCharactersByEditor,
                             setInitialState,
                             enableHyperLinks = false,
                             enableColors = false,
                             enableRemoveFormatting = false,
                           }: {
  editorState: string;
  setValue: UseFormSetValue<any>;
  valueName: string;
  maxCharacters: number;
  minCharacters?: number;
  disabled?: boolean;
  errorName?: string;
  isRequired?: boolean;
  placeholder?: string;
  className?: string;
  isProposal?: boolean;
  isSimple?: boolean;
  alignEnabled?: boolean;
  setCountedCharactersByEditor?: React.Dispatch<React.SetStateAction<number>>;
  setInitialState?: (value: string) => void;
  enableHyperLinks?: boolean;
  enableColors?: boolean;
  enableRemoveFormatting?: boolean;
}) => {
  const [count, setCount] = useState(0);
  const {formatMessage} = useIntl();
  const editorRef = useRef(null);
  const INITIAL_NEW_LINE = 1;
  const maxCharactersWithNewLine = maxCharacters + INITIAL_NEW_LINE;
  const minCharactersWithNewLine = (minCharacters ?? 0) + INITIAL_NEW_LINE;
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (editorRef) {
      if (setInitialState && isFirstRender) {
        const initialRichTextState = (
          (document.getElementById(valueName) as HTMLDivElement).querySelector('.ql-editor') as HTMLDivElement
        ).innerHTML;
        setInitialState(initialRichTextState);
        setValue(valueName, initialRichTextState, {shouldDirty: true, shouldTouch: true});
        setIsFirstRender(false);
      }
      const currentLength = textUtils((editorRef as any).current.editor);
      if (count !== currentLength) {
        setCount(currentLength);
      }
    }
  });
  const minLength = minCharacters ?? 150;
  const remainingCharacters = maxCharactersWithNewLine - count;
  const isValid = remainingCharacters >= 0;
  const isEmpty = isRequired && count - minLength <= 0 && !(isProposal && !editorState.length);

  const handleUpdate = (value: string, _delta: DeltaStatic, _source: Sources, editor: Quill) => {
    const cCount = textUtils(editor);
    setValue(
      valueName,
      value
        .replace(/(<p>(<br>|\s*)<\/p>\s*){3,}/g, '<p><br></p>') // Replace multiple <p><br> with a single one
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .replaceAll('&nbsp;', ' '),
      {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      }
    );

    setCount(cCount);
    if (setCountedCharactersByEditor) {
      setCountedCharactersByEditor(cCount - INITIAL_NEW_LINE);
    }
    if (errorName) {
      if (minCharacters) {
        setValue(
          errorName,
          maxCharactersWithNewLine - cCount >= 0 && cCount >= minCharactersWithNewLine
            ? ''
            : formatMessage({id: 'common.text.long'}),
          {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          }
        );
      } else {
        setValue(errorName, maxCharactersWithNewLine - cCount >= 0 ? '' : formatMessage({id: 'common.text.long'}), {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }
    }
  };

  const toolbarId = `${valueName}-toolbar`;

  const modules = {
    toolbar: `#${toolbarId}`,
    history: {
      delay: 1000,
      maxstack: 100,
      useronly: false,
    },
  };

  const formats = isSimple
    ? []
    : [
      'bold',
      'italic',
      'underline',
      'strike',
      'list',
      'bullet',
      `${alignEnabled ? 'align' : ''}`,
      `${enableHyperLinks ? 'link' : ''}`,
      `${enableColors ? 'color' : ''}`,
    ];
  return (
    <EditorContainer className={className} isSimple={isSimple}>
      <QuillContainer id={valueName}>
        <ReactQuill
          ref={editorRef}
          theme="snow"
          bounds={`#${toolbarId}`}
          value={editorState}
          onChange={handleUpdate as any}
          modules={modules}
          formats={formats}
          preserveWhitespace
          readOnly={disabled}
          placeholder={placeholder}
          onKeyDown={evt => {
            if (evt.key === 'Escape') {
              evt.target.blur();
            }
          }}
        />

        <CustomToolbar
          id={toolbarId}
          undo={() => (editorRef as unknown as History).current.editor.history.undo()}
          redo={() => (editorRef as unknown as History).current.editor.history.redo()}
          disabled={disabled || isSimple}
          enableHyperLinks={enableHyperLinks}
          alignEnabled={alignEnabled}
          enableColors={enableColors}
          enableRemoveFormatting={enableRemoveFormatting}
        />
      </QuillContainer>
      {(!isValid || isEmpty) && (
        <ErrorMessage>
          <FormattedMessage
            id={isEmpty ? 'common.text.required' : 'common.text.long'}
            values={{count: `${minLength}`}}
          />
        </ErrorMessage>
      )}
      {!disabled && <CharacterLimit valid={isValid}>{remainingCharacters}</CharacterLimit>}
    </EditorContainer>
  );
};

interface History {
  current: {
    editor: {
      history: {
        undo: () => void;
        redo: () => void;
      };
    };
  };
}

const CustomToolbar = ({
                         id,
                         undo,
                         redo,
                         disabled,
                         enableHyperLinks,
                         alignEnabled,
                         enableColors,
                         enableRemoveFormatting,
                       }: {
  id: string;
  undo: () => void;
  redo: () => void;
  disabled?: boolean;
  enableHyperLinks?: boolean;
  alignEnabled?: boolean;
  enableColors: boolean;
  enableRemoveFormatting: boolean;
}) => {
  return (
    <ToolbarContainer id={id} disabled={disabled}>
      <button onClick={undo} disabled={disabled} type="button" aria-label="undo">
        <SparkIcons className="spark-icon spark-icon--fill spark-icon-arrow-chevron-left spark-icon--md" />
      </button>
      <button onClick={redo} disabled={disabled} type="button" aria-label="redo">
        <SparkIcons className="spark-icon spark-icon--fill spark-icon-arrow-chevron-right spark-icon--md" />
      </button>
      <ToolbarRule className="spark-mar-l-1" />
      <button className="ql-bold" disabled={disabled} type="button" aria-label="bold text" />
      <button className="ql-italic" disabled={disabled} type="button" aria-label="italic text" />
      <button className="ql-underline" disabled={disabled} type="button" aria-label="underline text" />
      <ToolbarRule />
      <button className="ql-list" value="bullet" disabled={disabled} type="button" aria-label="add unordered list" />
      <button className="ql-list" value="ordered" disabled={disabled} type="button" aria-label="add ordered list" />
      {enableHyperLinks && (
        <>
          <ToolbarRule />
          <button className="ql-link" disabled={disabled} type="button" aria-label="add link" />
        </>
      )}
      {alignEnabled && (
        <>
          <span className="ql-formats">
            <button className="ql-align" type="button" value="" aria-label="align left" />
            <button className="ql-align" type="button" value="center" aria-label="align center" />
            <button className="ql-align" type="button" value="right" aria-label="align right" />
            <button className="ql-align" type="button" value="justify" aria-label="align justify" />
          </span>
        </>
      )}
      {enableColors && (
        <>
          <ToolbarRule />
          <select className="ql-color" disabled={disabled}>
            <option value="#B22222" />
            <option value="green" />
            <option value="blue" />
            <option value="orange" />
            <option value="violet" />
            <option value="#6E6E6E" />
            <option value="black" />
          </select>
        </>
      )}
      {enableRemoveFormatting && (
        <>
          <ToolbarRule />
          <button className="ql-clean" disabled={disabled} type="button" aria-label="remove format" />
        </>
      )}
      {alignEnabled && <ToolbarRule />}
    </ToolbarContainer>
  );
};

const SparkIcons = styled.i`
  transform: translateY(-3px) scale(0.8);
  color: ${colors.grey1000};
`;

const ToolbarRule = styled.span`
  margin: 0 0.5rem;
  height: 24px;
  border-right: 1px solid ${colors.grey200};
  float: left;
`;

const ToolbarContainer = styled.div<{disabled?: boolean}>`
  ${props => (props.disabled ? 'display: none;' : '')}
`;

const QuillContainer = styled.div`
  & .ql-editor {
    min-height: 15rem;
    max-height: 15rem;
    overflow: auto;
    height: fit-content;
    word-break: break-word;
  }
`;

const ErrorMessage = styled.span`
  display: block;
  color: ${colors.white};
  background-color: ${colors.red};
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0.75rem 1rem;
  font-size: 1.3333333333rem;
  font-weight: 700;
  transform: translateY(-0.1rem);
`;

const EditorContainer = styled.div<{isSimple?: boolean}>`
  & .tox-editor-container {
    flex-direction: column-reverse !important;
  }

  & .ql-container {
    border-radius: 0.25rem 0.25rem ${props => (props.isSimple ? '0.25rem 0.25rem' : '0 0')};
  }

  & .tox-editor-header {
    border-top: 1px solid ${colors.grey200} !important;
  }

  & .ql-editor[contenteditable='false'] {
    background-color: ${colors.grey100};
  }

  & .description-toolbar {
    border-radius: 0 0 0.25rem 0.25rem;
  }
`;

export const CharacterLimit = styled.p<{valid?: boolean}>`
  display: flex;
  justify-content: flex-end;
  color: ${({valid}) => (valid ? colors.highlightGrey : colors.red)};
`;
