import insurance from './insurance';
import definition from './definition';
import common from '@scm/components/translations/common';
import regionalization from './regionalization';
import pricing from './pricing';
import servicesAndSecurity from './servicesAndSecurity';
import review from './review';
import proposalView from './proposalView';
import {bundle} from './bundle';
import {publicStandard} from '@scm/components/translations/infoTexts/publicStandard';
import {privateData} from '@scm/components/translations/infoTexts/privateData';
import {privateStandard} from '@scm/components/translations/infoTexts/privateStandard';
import {onboardingInfo} from '@scm/components/translations/onboardingInfo';
import processScheme from '@scm/components/translations/processScheme';
import tabs from '@scm/components/translations/tabs';
import {certify} from '@scm/components/translations/certify';
import {auditLog} from '@scm/components/translations/auditLog';
import productDetails from '@scm/components/translations/productDetails';

const proposal = {
  ...certify,
  ...common,
  ...insurance,
  ...definition,
  ...regionalization,
  ...pricing,
  ...servicesAndSecurity,
  ...review,
  ...proposalView,
  ...productDetails,
  ...tabs,
  ...bundle,
  ...auditLog,
  ...onboardingInfo,
  ...publicStandard,
  ...privateData,
  ...privateStandard,
  ...processScheme,
  'proposal.breadcrumb': 'Add New Product',
  'proposal.title': 'Add New Product (Proposal)',
  'proposal.scribeScripts.title': 'Add New Scribe Scripts',
  'proposal.pfKeys.title': 'Add New PF Keys',
  'proposal.proposalType': 'Proposal Type',
  'proposal.proposalType.label': 'Select proposal type',
  'proposal.proposalType.message.title': 'Proposal Type.',
  'proposal.proposalType.message.content': 'Proposal processing steps and time depends on its type.',
  'proposal.privateRedApp.message.title': 'Private Red App',
  'proposal.privateRedApp.message.content': "Private Red Apps are available to your agency's internal users only.",
  'proposal.publicRedApp.message.title': 'Public Red App',
  'proposal.publicRedApp.message.content.firstLine':
    "For Public Red Apps, we'll respond to your proposal within <strong>72 business hours</strong> and let you know if your concept is approved.",
  'proposal.publicRedApp.message.content.secondLine':
    'Proposal submission is a first step for public Red App, and if it is approved, then certification and publication process will be waiting for you. Public Red Apps appear in the Marketplace to shoppers after publication.',
  'proposal.pfKeys.message.title': 'PF Keys',
  'proposal.pfKeys.message.content':
    "PF Keys are approved and published immediately. PF Keys are available to your agency's internal users only.",
  'proposal.scribeScripts.message.title': 'Scribe Scripts',
  'proposal.providers.label': 'Choose provider',
  'proposal.providers.message.content':
    'We have identified that you are currently assigned to several providers. Before you move to the next step, please indicate for which one you would like to submit this proposal.',
  'proposal.scribeScripts.message.content':
    "Scribe Scripts are approved and published immediately. Scribe Scripts are available to your agency's internal users only.",
  'proposal.providers.list.label': 'Providers List',
  'proposal.providers.warningMessage.title': 'Warning',
  'proposal.providers.warningMessage.content':
    'Your profile is not assigned to any developer company, please contact {email} if you need to submit new proposal',
  'proposal.insurance': 'Insurance',
  'proposal.definition': 'Definition',
  'proposal.regionalization': 'Regionalization',
  'proposal.pricing': 'Pricing',
  'proposal.servicesAndSecurity': 'Services & Security',
  'proposal.security': 'Security',
  'proposal.review': 'Review',
  'proposal.definitionAndReview': 'Definition & Review',
  'proposal.emptyStep': '…',
  'proposal.insurance.title': 'Insurance General Requirements',
  'proposal.insurance.additionalTitle':
    'Please read the Insurance General Rules by scrolling down and then accept it. You must agree to Insurance General Rules in order to move to next step.',
  'proposal.saveDraft': 'Save Draft',
  'proposal.saveDraft.success.title': 'Saved.',
  'proposal.saveDraft.success.description':
    'Your Red App Proposal was saved and you can find it on the "My Products" list.',
  'proposal.saveDraft.error.title': 'Error.',
  'proposal.saveDraft.error.description': "Can't save draft, please try again later.",
  'proposal.changeStatus.error.description': "Can't change status, please try again later.",
  'proposal.update.error.description': "Can't update proposal, please try again later.",
  'proposal.update.success.description': 'Your proposal was updated.',
  'proposal.requiredFields.message': 'Please fill all required questions.',
  'proposal.approved.message.content':
    'Your proposal was approved. You can start editing storefront content and set up the Red App details, as well as submit the first version bundle.',
  'proposal.approved.error': 'Updates cannot be saved, please try again or contact {email}.',
  'proposal.approved.errorTitle': 'Updates failed.',
  'proposal.approved.success': 'Your storefront updates were saved.',
  'proposal.approved.successTitle': 'Saved.',
  'proposal.approved.skuExists.error.title': 'Warning',
  'proposal.approved.skuExists.error.definition': 'Something went wrong, please contact our support team.',
  'proposal.description.minLength': 'This field must have at least 150 characters.',
};

export default proposal;
