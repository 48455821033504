import HtmlToText from 'html-to-text';
import {countEncodedTags, encodeHtmlEntities} from "./textUtils";

const ELLIPSIS = '...';
const MAX_LENGTH = 150;
const CUT_LENGTH = MAX_LENGTH - ELLIPSIS.length;

export const richTextShortener = (richText: string) => {
  const el = document.createElement('div');
  el.innerHTML = richText;
  richText = Array.from(el.children).reduce((a, b) => a + b.textContent, '');
  richText = HtmlToText.htmlToText(richText);
  richText = richText.replace(/["']/g, '');
  richText = richText.replace(/(\r\n|\n|\r)/gm, '');

  richText = richText.slice(0, CUT_LENGTH);
  const encodedHtmlText = encodeHtmlEntities(richText);
  if (encodedHtmlText.length > MAX_LENGTH) {
    const toRemove = countEncodedTags(encodedHtmlText);
    return richText.slice(0, -toRemove) + ELLIPSIS;
  }
  return richText + ELLIPSIS;
};
