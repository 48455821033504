import React, {createContext} from 'react';
import {useForm} from 'react-hook-form';
import {countryCodesName, ProposalDataValues, regionCodesName} from './ProposalDataValues';
import {initialValues} from './proposalDataConstants';
import proposal from '../../../translations/proposal';
import {IntlProvider} from 'react-intl';
import {ProposalDataViewContextValue} from './ProposalDataViewContextValue';
import {yupResolver} from '@hookform/resolvers/yup';
import {updateProposalSchema} from '../../../schemas/updateProposalSchema';
import {
  globalRegionalizationCheckboxName,
  isPaidDescriptionName,
  redAppCategoryName,
} from '../../proposalForm/ProposalValues';
import {Confirmation} from '@scm/components/confirmation/confirmation';

const isPaidName = 'isPaid';

export const ProposalDataViewContext = createContext<ProposalDataViewContextValue>(
  {} as unknown as ProposalDataViewContextValue
);
const language = 'en';

const ProposalViewDataProvider = ({children}: {children: JSX.Element}) => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    formState: {isDirty: isDirtyForm, errors, isValid: isValidForm},
    clearErrors,
  } = useForm<ProposalDataValues>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(updateProposalSchema),
  });

  const isDirty = isDirtyForm || !watch(redAppCategoryName).length;
  const isValid =
    isValidForm &&
    (watch(globalRegionalizationCheckboxName) !== Confirmation.Yes ||
      watch(regionCodesName).length ||
      watch(countryCodesName).length);
  const value = {handleSubmit, watch, control, setValue, reset, isDirty, errors, isValid};

  if (watch(isPaidName) === Confirmation.No && watch(isPaidDescriptionName) !== null) {
    setValue(isPaidDescriptionName, null, {shouldDirty: true, shouldTouch: true, shouldValidate: true});
    clearErrors();
  } else if (watch(isPaidName) === Confirmation.Yes && watch(isPaidDescriptionName) === undefined) {
    setValue(isPaidDescriptionName, '', {shouldDirty: true, shouldTouch: true, shouldValidate: true});
  }

  return (
    <IntlProvider locale={language} key={language} messages={proposal}>
      <ProposalDataViewContext.Provider value={value}>{children}</ProposalDataViewContext.Provider>
    </IntlProvider>
  );
};

export default ProposalViewDataProvider;
