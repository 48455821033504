import {StyledH2, StyledH3} from '../developerPartner/ContactDetails';
import {FormattedMessage, useIntl} from 'react-intl';
import {Fields} from '../../components/EndMessage';
import RadioButtons from '@scm/components/form/RadioButtons';
import {Button, RadioButton, RadioButtonGroup, Tooltip} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {FormProvider, SubmitHandler, useForm, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import React, {useContext} from 'react';
import {RadioContainer} from '../developerPartner/Pricing';
import Icon from '@scm/components/icons/Icon';
import {
  RadioButtonNamesAndValues,
  redAppPricingTypeName,
  redAppPricingValueTypeName,
} from './RedAppProviderFormContent';
import {Confirmation} from '@scm/proposal/pages/proposalForm/steps/serviceAndSecurity/ServiceAndSecurityValues';
import {OnboardRedAppProviderRequest, RedAppPricingTypePricingTypeEnum} from '../../../generated/onboardings';
import {Text} from '@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage';
import useAddRoleToTooltip from '@scm/components/accessibility/hooks/useAddRoleToTooltip';
import Legend from '@scm/components/accessibility/Legend';
import {EventCategories} from '../developerPartner/TermsAndConditions';
import {GaContext} from '../../../utils/gaContext';
import {onboardingRedAppProviderLatestStepName} from "../../JoinUsContentBox";
import {buildGaActionLabel} from "@scm/components/ga/actions";

const PricingDetails = ({
  setCurrentStep,
  setValue,
  watch,
  currentStep,
  isPublicRedApp,
}: {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setValue: UseFormSetValue<RadioButtonNamesAndValues>;
  watch: UseFormWatch<RadioButtonNamesAndValues>;
  currentStep: number
  isPublicRedApp?: boolean;
}) => {
  const {formatMessage} = useIntl();
  const tooltipRef = useAddRoleToTooltip();
  const previousStep = () => setCurrentStep(prevState => prevState - 1);
  const methods = useForm<OnboardRedAppProviderRequest>({mode: 'onChange'});
  const {logEvent} = useContext(GaContext);
  const formSubmitHandler: SubmitHandler<OnboardRedAppProviderRequest> = () => {
    const redAppTypeTag = isPublicRedApp ? 'ga.tag.redAppType.public' : 'ga.tag.redAppType.private';
    if ((sessionStorage.getItem(onboardingRedAppProviderLatestStepName) ?? 0) < currentStep) {
      logEvent({category: EventCategories.JoinUs, action: buildGaActionLabel('ga.action.onboarding.movedToProposal', 'ga.tag.partnerNetwork.redAppProvider', redAppTypeTag)});
      setCurrentStep(prevState => prevState + 1);
    }
  };

  const isContinueDisabled = isPublicRedApp && watch(redAppPricingValueTypeName) === undefined;

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <div className="spark-panel">
          <div className="spark-panel__content">
            <StyledH2>
              <FormattedMessage id="pricingDetails.title" />
            </StyledH2>
            <Text data-margin-sm>
              {isPublicRedApp ? (
                <FormattedMessage id="pricingDetails.description.publicRedApps" />
              ) : (
                <FormattedMessage id="pricingDetails.description.privateRedApps" />
              )}
            </Text>
            <Fields>
              <StyledH3 className="spark-pad-t-0">
                <FormattedMessage id="pricingDetails.certificationFee.title" />
                <Tooltip
                  anchorX="right"
                  anchorY="middle"
                  toggleEl={<Icon className="spark-icon-info-circle spark-icon--fill" />}
                  ref={tooltipRef}
                >
                  <FormattedMessage id="pricingDetails.certificationFee.description" />
                </Tooltip>
              </StyledH3>
              <RadioContainer>
                <div className="spark-mar-l-1">
                  <RadioButtons
                    name="certificationFee"
                    labels={[formatMessage({id: 'pricingDetails.certificationFee.perEachRedApp'})]}
                    values={['Yes']}
                    disabled
                    checked
                  />
                </div>
              </RadioContainer>
              <StyledH3 className="spark-pad-t-0">
                <FormattedMessage id="pricingDetails.monthlyFee.title" />
              </StyledH3>
              {isPublicRedApp ? (
                <RadioButtonGroup
                  name={redAppPricingTypeName}
                  value={watch(redAppPricingValueTypeName)}
                  onChange={event => {
                    setValue(redAppPricingValueTypeName, event.target.value as Confirmation, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });
                  }}
                >
                  <Legend text={formatMessage({id: 'pricingDetails.monthlyFee.title'})} />
                  <RadioButton
                    className="col-xs-12"
                    label={formatMessage({id: 'pricingDetails.monthlyFee.eprFee'})}
                    value={RedAppPricingTypePricingTypeEnum.EprFee}
                    data-testid="EPR"
                  />
                  <RadioButton
                    className="col-xs-12"
                    label={formatMessage({id: 'pricingDetails.monthlyFee.revenueShare'})}
                    value={RedAppPricingTypePricingTypeEnum.RevenueShare}
                  />
                </RadioButtonGroup>
              ) : (
                <RadioContainer>
                  <div className="spark-mar-l-1">
                    <RadioButtons
                      name="redAppPricingType"
                      labels={[formatMessage({id: 'pricingDetails.monthlyFee.privateRedApp'})]}
                      values={[RedAppPricingTypePricingTypeEnum.EprFee]}
                      disabled
                      checked
                    />
                  </div>
                </RadioContainer>
              )}
            </Fields>
          </div>
        </div>
        <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
          <Button type="button" onClick={previousStep} size={ButtonSize.SMALL} secondary>
            <FormattedMessage id="joinUsPage.form.backButton" />
          </Button>
          <Button type="submit" disabled={isContinueDisabled} size={ButtonSize.SMALL}>
            <FormattedMessage id="joinUsPage.form.button" />
          </Button>
        </nav>
      </form>
    </FormProvider>
  );
};

export default PricingDetails;
