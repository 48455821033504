export enum UsersRoles {
  'ROLE_ADP' = 'ROLE_ADP',
  'ROLE_AGENCY_ADMIN' = 'ROLE_AGENCY_ADMIN',
  'ROLE_RED_APP_DEVELOPER' = 'ROLE_RED_APP_DEVELOPER',
  'ROLE_FIRST_LEVEL_SUPPORT' = 'ROLE_FIRST_LEVEL_SUPPORT',
  'ROLE_SABRE_ADMIN' = 'ROLE_SABRE_ADMIN',
  'ROLE_SECURITY_ADMIN' = 'ROLE_SECURITY_ADMIN',
  'ROLE_SECOND_LEVEL_SUPPORT' = 'ROLE_SECOND_LEVEL_SUPPORT',
  'ROLE_MANAGE_COMPANY' = 'ROLE_MANAGE_COMPANY',
  'ROLE_SUBMIT_PROPOSAL' = 'ROLE_SUBMIT_PROPOSAL',
}
