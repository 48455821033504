import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {ProvisioningType} from '../../../generated/proposal';
import colors from '@scm/components/assets/colors';
import {deviceWidths} from '@scm/components/assets/deviceWidth';

const ProposalViewHeader = ({name, provisioningType}: {name?: string; provisioningType?: ProvisioningType}) => {
  return (
    <ProductHeader className="spark-flex">
      <IconContainer>
        <BlankIcon />
        <Text className="spark-flex">
          <h1>{name}</h1>
          <ProposalType>
            <FormattedMessage id="proposalView.proposalType" values={{provisioningType}} />
          </ProposalType>
        </Text>
      </IconContainer>
    </ProductHeader>
  );
};

const ProposalType = styled.p`
  &:first-letter {
    text-transform: uppercase;
  }
`;

const BlankIcon = styled.div`
  margin-top: 8px;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  padding: 0.5rem;
  border-radius: 25%;
  background-color: ${colors.grey300};
  border: 1px solid ${colors.disabledGrey};
`;

const IconContainer = styled.div`
  display: flex;
  height: 80px;
  @media (max-width: ${deviceWidths.xs}px) {
    transform: scale(0.8) translateX(-4rem);
  }
`;

const ProductHeader = styled.div`
  justify-content: space-between;
  @media (max-width: ${deviceWidths.sm}px) {
    flex-wrap: wrap;
  }
  margin-bottom: 2rem;
`;

const Text = styled.div`
  padding: 0.5rem;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;

  & h1 {
    margin-bottom: 0;
  }
`;

export default ProposalViewHeader;
