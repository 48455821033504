import React, {useState} from 'react';
import AdditionalStatement from '../../../components/content/AdditionalStatement';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {Button, Modal} from '@sabre/spark-react-core';
import colors from '@scm/components/assets/colors';
import ProposalChangeNameForm from '../proposalChangeNameForm/ProposalChangeNameForm';
import {ProposalResponse, ProposalStatus} from '../../../generated/proposal';
import {createPortal} from 'react-dom';
import {
  isAdpRole,
  isRedAppDeveloperRole,
  isSabreAdminRole,
  isSecondLevelSupportRole,
} from '@scm/authentication/utils/authentication';
import parse from 'html-react-parser';

const ProposalViewContent = ({
  description,
  proposal,
  setLoading,
}: {
  description: string;
  proposal: ProposalResponse;
  setLoading: (flag: boolean) => void;
}) => {
  const {formatMessage} = useIntl();
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);
  const root = document.getElementById('root') as HTMLDivElement;

  const isChangeNameEnable = () => {
    return !(isRedAppDeveloperRole() || isSabreAdminRole() || isAdpRole() || isSecondLevelSupportRole());
  };

  return (
    <ContentContainer>
      <ParagraphContainer className="spark-mar-b-2">
        <AdditionalStatement id="proposalView.detail.additionalText" />
      </ParagraphContainer>
      {(proposal.status === ProposalStatus.Submitted || proposal.status === ProposalStatus.InReview) && (
        <>
          <ChangeRedAppNameButton onClick={handleOpenModal} textOnly disabled={isChangeNameEnable()}>
            <FormattedMessage id="proposalView.changeName.button" />
          </ChangeRedAppNameButton>
          <p className="spark-small spark-mar-t-.5">
            <FormattedMessage id="proposalView.changeName.additionalText" />
          </p>
        </>
      )}
      <div className="row">
        <p className="col-xs-2">
          <FormattedMessage id="proposalView.description.label" />
        </p>
        <DescriptionText className="col-xs-10">{parse(description)}</DescriptionText>
      </div>
      {createPortal(
        <Modal
          onClose={handleCloseModal}
          open={openModal}
          title={formatMessage({id: 'proposalView.changeName.modal.title'})}
        >
          <ProposalChangeNameForm handleCloseModal={handleCloseModal} proposal={proposal} setLoading={setLoading} />
        </Modal>,
        root
      )}
    </ContentContainer>
  );
};

const ParagraphContainer = styled.div`
  span {
    margin-left: -1rem;
  }
`;

export const DescriptionText = styled.p`
  overflow-wrap: anywhere;
  white-space: break-spaces;
`;

const ChangeRedAppNameButton = styled(Button)`
  color: ${colors.sparkBlue} !important;
`;

export const ContentContainer = styled.div`
  flex-basis: 80%;
  padding: 2rem;
  font-weight: 400;
  border: none;
  overflow: auto;
`;

export default ProposalViewContent;
