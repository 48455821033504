const color = {
  white: '#fff',
  black: '#000',
  red: '#ac0000',
  lightRed: '#e50000',
  darkRed: '#910000',
  grey50: '#f6f6f7',
  grey100: '#eee',
  grey150: '#e4e4e4',
  grey200: '#ccc',
  grey300: '#ddd',
  grey500: '#697581',
  grey700: '#4a5767',
  grey800: '#37414d',
  grey900: '#333',
  grey1000: '#444',
  highlightGrey: '#555',
  highlightLightGrey: '#666',
  highlightBlue100: '#abded4',
  highlightBlue200: '#e2eaf3',
  blue100: '#f2f7fa',
  blue200: '#a2b5cc',
  blue300: '#8f9db0',
  blue500: '#267da9',
  sparkBlue: '#006ba8',
  successGreen: '#5e8120',
  sparkGreen: '#23826f',
  neutralBlue: '#4a5767',
  primaryActionGreen: '#23826f',
  warningYellow: '#f9b646',
  warningDarkYellow: '#99541c',
  tierPlatinum: '#757070',
  tierPlatinumHover: '#7f7f7f',
  tierGold: '#8f7001',
  tierGoldHover: '#ad8700',
  tierSilver: '#ccc',
  tierSilverHover: '#b1b1b1',
  transparent: 'transparent',
  disabledGrey: '#767676',
  disabledMenuItem: '#b1b1b1',
  statusInfoGrey: '#676767',
  lightGreyHr: '#bdc8cc',
  disabledTextFieldGrey: '#898989',
};

export default color;
