import React, {ChangeEvent, ReactElement, useCallback, useContext, useEffect, useState} from 'react';
import {ProposalContainer} from '../../ProposalTypePage';
import PageHeader from '../../../../components/content/PageHeader';
import AdditionalStatement from '../../../../components/content/AdditionalStatement';
import {ButtonContainerInsurance} from '../InsurancePage';
import {Button, Checkbox, TextInput} from '@sabre/spark-react-core';
import {ButtonSize, MessageStatus} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import {ProposalContext} from '../../ProposalForm';
import {Controller} from 'react-hook-form';
import {TextArea} from '../definitionPage/definitionComponents/Description';
import {ActionBadges, ExpandPanel, MultiSelect} from '@scm/components';
import {CheckAllOptions, MultiSelectMainProps, SecondLevelOptions} from '@scm/components/form/multiSelect/MultiSelect';
import {countriesInRegion, regions} from '@scm/components/assets/regionsAndCountries';
import styled from 'styled-components';
import {
  allRegionsName,
  countryCodesName,
  globalRegionalizationCheckboxName,
  regionalizationDescriptionName,
  regionCodesName,
} from '../../ProposalValues';
import {MultiselectErrorMessage} from '../definitionPage/definitionComponents/Category';
import ButtonWithTooltip from '@scm/components/form/ButtonWithTooltip';
import {createNestedObjectName} from '../serviceAndSecurity/components/servicesComponents/Services';
import {basicTextFieldMinLength, minDescriptionMessage} from '../../../../schemas/proposalConstants';
import gaEvent from '@scm/components/ga/googleAnalyticsEvent';
import {onboardingRedAppProviderLatestStepName} from "@scm/onboarding/joinUs/JoinUsContentBox";
import {proposalPublicRedAppFormStepNumberName} from "../../PagesMap";
import {buildGaActionLabel} from "@scm/components/ga/actions";

type ActionBadgesDisplaysType = () => Array<string>;

const Regionalization = ({currentStep}: {currentStep: number}) => {
  const {formatMessage} = useIntl();
  const {handleContinue, handleCancel, handleSaveDraft, control, watch, setValue, isOnboarding} =
    useContext(ProposalContext);
  const textAreaMaxLength = 250;
  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  const allRegions = watch(allRegionsName);
  const regionCodesNamesLength = watch(regionCodesName).length;
  const countryCodesNamesLength = watch(countryCodesName).length;
  const regionalizationDescription = watch(regionalizationDescriptionName) ?? '';
  const regionalizationDescriptionValid =
    !regionalizationDescription.length || regionalizationDescription.length >= basicTextFieldMinLength;
  const isValid = allRegions
    ? regionalizationDescriptionValid
    : !!(countryCodesNamesLength && regionalizationDescriptionValid);

  const allCountries = Object.values(countriesInRegion);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const validationOptions = {shouldTouch: true, shouldValidate: true, shouldDirty: true};

  useEffect(() => {
    countryCodesNamesLength && setShouldShowBanner(true);
  }, [countryCodesNamesLength]);

  const setGlobal = useCallback(() => {
    setValue(regionCodesName, ['Global'], validationOptions);
    setValue(countryCodesName, [], validationOptions);
  }, [setValue]);

  const setCountriesInSelectedRegionsOnly = useCallback(() => {
    const regionCodes = watch(regionCodesName);
    const countriesInSelectedRegions = regionCodes
      .map(regionCode => Object.keys((countriesInRegion as unknown as {[key: string]: Object})[regionCode]))
      .flat();

    setValue(countryCodesName, [...watch(countryCodesName), ...countriesInSelectedRegions], validationOptions);
  }, [setValue]);

  useEffect(() => {
    if (watch(regionCodesName).includes('None') && regionCodesNamesLength < 2) {
      setValue(regionCodesName, [], validationOptions);
    }
  }, [watch, setValue, regionCodesNamesLength]);

  useEffect(() => {
    if (allRegions) {
      setGlobal();
    } else if (watch(regionCodesName) || watch(countryCodesName)) {
      setCountriesInSelectedRegionsOnly();
    }
    setValue(createNestedObjectName(globalRegionalizationCheckboxName), !allRegions);
    setIsLoadingData(false);
  }, [setGlobal, setCountriesInSelectedRegionsOnly, watch, setIsLoadingData]);

  const multiSelectMainProps: MultiSelectMainProps = {
    firstLevelName: regionCodesName,
    setValue,
    watch,
    options: regions,
  };

  const secondLevelOptions: SecondLevelOptions = {
    optionsSecondLevel: allCountries,
    secondLevelName: countryCodesName,
  };

  const checkAllOptions: CheckAllOptions = {
    checkAllLabel: formatMessage({
      id: 'regionalization.checkAll.label',
    }),
    checkAllName: allRegionsName,
  };

  const deleteGlobal = () => {
    setValue(regionCodesName, [], validationOptions);
    setValue(countryCodesName, [], validationOptions);
    setValue(allRegionsName, false, validationOptions);
  };

  const deleteRegion = (region: string, name: string) => {
    setValue(
      regionCodesName,
      watch(regionCodesName).filter((code: string) => (regions as {[key: string]: string})[code] !== name),
      validationOptions
    );

    setValue(
      countryCodesName,
      watch(countryCodesName).filter(
        (currentCode: string) =>
          !Object.keys((countriesInRegion as unknown as {[key: string]: Object})[region]).includes(currentCode)
      ),
      validationOptions
    );
  };

  const deleteCountry = (name: string) => {
    setValue(
      countryCodesName,
      watch(countryCodesName).filter(
        (code: string) =>
          (
            allCountries.reduce((previousValue, currentValue) => Object.assign(previousValue, currentValue), {}) as {
              [key: string]: string;
            }
          )[code] !== name
      ),
      validationOptions
    );
  };

  const deleteCountryOrRegion = (name: string) => {
    const globalName = 'global';
    const region = Object.keys(regions)[Object.values(regions).indexOf(name)];

    if (name?.toLowerCase() === globalName) {
      deleteGlobal();
    } else if (region) {
      deleteRegion(region, name);
    } else {
      deleteCountry(name);
    }
  };

  const handleCheckboxChange = (onChange, event: ChangeEvent, value: boolean) => {
    onChange(event);
    if (value) {
      setValue(allRegionsName, true, validationOptions);
      setGlobal();
      setShouldShowBanner(false);
    } else {
      deleteGlobal();
    }
  };

  const actionBadgesDisplays: ActionBadgesDisplaysType = () => {
    if (allRegions) {
      return ['Global'];
    }
    const selectedRegions = [
      ...watch(regionCodesName).map((code: string) => (regions as {[key: string]: string})[code]),
    ];
    const selectedCountriesNotInSelectedRegions = Object.keys(regions)
      .filter(regionCode => !watch(regionCodesName).includes(regionCode))
      .map(regionCode => (countriesInRegion as unknown as {[key: string]: Object})[regionCode])
      .map(countries => Object.keys(countries).filter(countryCode => watch(countryCodesName).includes(countryCode)))
      .flat()
      .map(
        (code: string) =>
          (
            allCountries.reduce((previousValue, currentValue) => Object.assign(previousValue, currentValue), {}) as {
              [key: string]: string;
            }
          )[code]
      );

    return [...selectedRegions, ...selectedCountriesNotInSelectedRegions];
  };

  const handleRegionalizationContinue = () => {
    handleContinue(false);
    const sessionStorageKey = isOnboarding ? onboardingRedAppProviderLatestStepName : proposalPublicRedAppFormStepNumberName;
    if ((sessionStorage.getItem(sessionStorageKey) ?? 0) < currentStep) {
      const formType = isOnboarding ? 'ga.tag.partnerNetwork.redAppProvider' : 'ga.tag.adminCentre.redAppProposal';
      gaEvent(buildGaActionLabel('ga.action.proposal.completedRegionalization' , formType, 'ga.tag.redAppType.public'));
      sessionStorage.setItem(sessionStorageKey, currentStep.toString());
    }
  };

  return isLoadingData ? (
    <h1>Loading</h1>
  ) : (
    <>
      <ProposalContainer className="spark-panel spark-pad-l-2">
        <PageHeader id="regionalization.title" />
        <AdditionalStatement id="regionalization.additionalStatement" />

        <Controller
          name={createNestedObjectName(globalRegionalizationCheckboxName)}
          control={control}
          render={({field: {onChange, onBlur, value}}): ReactElement => (
            <Checkbox
              onBlur={onBlur}
              onChange={event => handleCheckboxChange(onChange, event, value as boolean)}
              checked={!!value}
              label={formatMessage({id: 'regionalization.checkbox.globalRegionalizationLabel'})}
            />
          )}
        />
        {watch(createNestedObjectName(globalRegionalizationCheckboxName)) && (
          <div>
            <p className="spark-mar-t-2 spark-mar-b-1">
              <FormattedMessage id="regionalization.checkbox.mainLabel" />
            </p>
            <ActionBadges
              badgeNames={actionBadgesDisplays()}
              deleteHandler={deleteCountryOrRegion}
              className="spark-mar-b-1 spark-mar-t-2"
            />
            <CountriesContainer
              className="col-lg-3 col-xs-8 spark-mar-t-0 spark-pad-l-0"
              onClick={evt => evt.stopPropagation()}
            >
              <TextInput
                name="searchText"
                value={searchText}
                label={formatMessage({
                  id: 'regionalization.search.label',
                })}
                onChange={(_, value) => {
                  setSearchText(value);
                  setIsOpen(!!value);
                }}
              />
              <ExpandPanelContainer>
                <ExpandPanel
                  panelHeader={formatMessage({
                    id: 'regionalization.select.header',
                  })}
                  isDarkTheme
                  isOpen={isOpen}
                  isTextInput
                >
                  <MultiSelect
                    multiSelectMainProps={multiSelectMainProps}
                    enableCheckAll={false}
                    checkAllOptions={checkAllOptions}
                    secondLevelOptions={secondLevelOptions}
                    searchText={searchText.toLowerCase()}
                  />
                </ExpandPanel>
                {!countryCodesNamesLength && shouldShowBanner && (
                  <MultiselectErrorMessage>
                    {formatMessage({id: 'tabs.regionalization.noData'})}
                  </MultiselectErrorMessage>
                )}
              </ExpandPanelContainer>
            </CountriesContainer>
          </div>
        )}
        <p className="spark-mar-t-0 spark-mar-b-1">
          <FormattedMessage id="regionalization.details.mainLabel" />
        </p>
        <Controller
          name={regionalizationDescriptionName}
          control={control}
          render={({field}): ReactElement => (
            <TextArea
              {...field}
              label={formatMessage({id: 'regionalization.details.label'})}
              placeHolder={formatMessage({id: 'regionalization.details.placeholder'})}
              multiLine
              characterCount
              maxLength={textAreaMaxLength}
              className="spark-mar-r-1 spark-mar-b-1 spark-mar-t-1"
              status={!regionalizationDescriptionValid ? MessageStatus.ERROR : undefined}
              statusMessage={minDescriptionMessage}
            />
          )}
        />
      </ProposalContainer>
      <ButtonContainerInsurance className="spark-mar-t-1 spark-flex">
        <Button secondary size={ButtonSize.SMALL} onClick={handleCancel} className="spark-mar-r-1">
          <FormattedMessage id="common.backButton" />
        </Button>
        <div className="row spark-mar-r-.5">
          {!isOnboarding && (
            <Button size={ButtonSize.SMALL} onClick={handleSaveDraft} className="spark-mar-r-1" secondary>
              <FormattedMessage id="proposal.saveDraft" />
            </Button>
          )}
          <ButtonWithTooltip
            handleContinue={handleRegionalizationContinue}
            isValid={isValid}
            messageId="proposal.requiredFields.message"
          />
        </div>
      </ButtonContainerInsurance>
    </>
  );
};

const CountriesContainer = styled.div`
  margin-bottom: -3rem;

  & input {
    border-right: none;
    border-radius: 0;
    box-shadow: 0 -5px 0 0 white;
    height: 5.06rem !important;
    transform: translateX(-10px) translateY(1px);
  }
`;

const ExpandPanelContainer = styled.div`
  position: relative;
  top: -72px;
  left: -11px;
  width: 81%;
  z-index: 0;
`;

export default Regionalization;
