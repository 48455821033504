import {FormattedMessage, useIntl} from 'react-intl';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {Button, Modal, TextInput} from '@sabre/spark-react-core';
import React, {useContext} from 'react';
import {Configuration, ProposalsApi} from '../generated/proposal';
import {proposalApiBaseLink} from '../assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {openToast} from '@scm/components/messaging/openToast';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {useNavigate} from 'react-router-dom';
import {largeTextFieldMaxLength} from '../schemas/proposalConstants';
import {ProposalDataViewContext} from '../pages/proposalViewPage/proposalViewDataTab/ProposalViewDataProvider';
import {reasonRejected} from '../pages/proposalViewPage/proposalViewDataTab/ProposalDataValues';
import styled from 'styled-components';
import {middleware} from '@scm/authentication/middleware/middlewareConfig';
interface Product {
  id: string;
  productName: string;
  productIcon?: string;
  type: string;
  updatedAt?: string;
  productStatus?: string;
  version?: string;
  isLatestVersionPatch?: boolean;
  status: string;
  isProposal?: boolean;
  sku: string;
  proposalId: string;
  isFavourite?: boolean;
}

const ProposalActionModal = ({
  open,
  setOpen,
  toggleOpenError,
  proposalId,
  proposalName,
  action,
  setLoading,
  setIsApproved,
  setItems,
  removeProposal,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  toggleOpenError?: (flag: boolean) => void;
  proposalId?: string;
  proposalName?: string;
  action?: string;
  setLoading?: (flag: boolean) => void;
  setIsApproved?: (flag: boolean) => void;
  setItems: React.Dispatch<React.SetStateAction<Array<Product>>>;
  removeProposal?: (id: string, name: string) => Promise<void>;
}) => {
  const {watch, setValue} = useContext(ProposalDataViewContext);
  const handleClose = (): void => setOpen(false);
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const approveStatus = 'approve';
  const deleteStatus = 'deleteProposal';
  const removeStatus = 'remove';
  const rejectStatus = 'reject';
  const actionName = action;

  const actionHandler = async () => {
    if (proposalId && action) {
      try {
        if (action === rejectStatus) {
          await new ProposalsApi(
            new Configuration({
              basePath: proposalApiBaseLink,
              accessToken: getAccessToken() as string,
              middleware,
            })
          ).reject({id: proposalId, reasonRejected: watch(reasonRejected)});
        } else {
          await new ProposalsApi(
            new Configuration({
              basePath: proposalApiBaseLink,
              accessToken: getAccessToken() as string,
              middleware,
            })
          )[action as typeof approveStatus]({id: proposalId});
        }

        if (action === deleteStatus) {
          navigate(InternalUrls.MyProducts);
          setItems?.(items => items.filter((item: Product) => item.id !== proposalId));
        }

        setLoading && setLoading(true);

        openToast(
          formatMessage({id: 'proposalDetails.changeStatus.title'}),
          formatMessage(
            {
              id: `proposalDetails.${action === deleteStatus ? deleteStatus : 'changeStatus'}.message`,
            },
            deleteStatus ? {name: proposalName} : undefined
          ),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      } catch (err) {
        toggleOpenError && toggleOpenError(true);
      }

      if (action == removeStatus) {
        if (removeProposal) {
          removeProposal(proposalId, proposalName || '').then(() => {
            navigate(InternalUrls.MyProducts);
            setItems?.(items => items.filter((item: Product) => item.id !== proposalId));
          });
        }
      }
    }

    if (action === approveStatus) {
      setIsApproved?.(true);
    }
  };

  const actionButtonHandler = () => {
    if (proposalId) {
      actionHandler();
    }
    handleClose();
  };

  return (
    <Modal onClose={handleClose} open={open} title={formatMessage({id: `proposalDetails.${actionName}Modal.title`})}>
      <DescriptionWrapper>
        <FormattedMessage id={`proposalDetails.${actionName}Modal.description`} />
      </DescriptionWrapper>
      {action === rejectStatus && (
        <TextInput
          value={watch(reasonRejected)}
          onChange={(_, value) => setValue(reasonRejected, value)}
          label={formatMessage({id: 'proposalDetails.rejectModal.reasonLabel'})}
          name={reasonRejected}
          characterCount
          maxLength={largeTextFieldMaxLength}
          multiLine
        />
      )}
      <nav className="spark-btn-group">
        <Button onClick={handleClose} className="spark-btn-group-secondary" size={ButtonSize.SMALL} secondary>
          <FormattedMessage id="proposalDetails.modal.cancelButton" />
        </Button>
        <Button className="spark-btn-group-primary" size={ButtonSize.SMALL} onClick={actionButtonHandler}>
          <FormattedMessage id={`proposalDetails.modal.${actionName}Button`} />
        </Button>
      </nav>
    </Modal>
  );
};

const DescriptionWrapper = styled.p`
  word-break: break-word;
  white-space: normal;
`;

export default ProposalActionModal;
