import React from 'react';
import {FormattedMessage} from 'react-intl';

const PageHeader = ({id, ariaLevel = 2, className}: {id: string; ariaLevel?: number; className?: string}) => {
  const isMarginOverridden = className?.includes('spark-mar');
  return (
    <h3 className={`${className} ${!isMarginOverridden && 'spark-mar-b-0 spark-mar-t-1'}`} aria-level={ariaLevel}>
      <FormattedMessage id={id} />
    </h3>
  );
};
export default PageHeader;
