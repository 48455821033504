import '.././translations/configureYupTranslations';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {BreadcrumbLink, BreadcrumbRowWrapper} from '@scm/components';
import {JoinUsContentBox} from './JoinUsContentBox';
import InternalUrls from '@scm/components/utils/pnUtils/internalUrls';

export const JoinUs = () => (
  <main>
    <BreadcrumbRowWrapper>
      <BreadcrumbLink intlId="common.breadcrumb.home" href={InternalUrls.Home} />
      <BreadcrumbLink intlId="common.breadcrumb.joinUs" />
    </BreadcrumbRowWrapper>
    <Content>
      <h1 className="spark-mar-b-2">
        <FormattedMessage id="joinUsPage.title" />
      </h1>
      <JoinUsContentBox />
    </Content>
  </main>
);

const Content = styled.div`
  padding: 3rem 6vw;
  min-height: 100vh;
`;
