import React, {ReactElement, useState} from 'react';
import {Button, Popover, ProgressIndicator, TextInput} from '@sabre/spark-react-core';
import ProposalNameDescription from './ProposalNameDescription';
import {Controller, FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import {ButtonSize, MessageStatus, ToastType} from '@sabre/spark-react-core/types';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {ButtonContainer} from '../../proposalForm/ProposalTypePage';
import {
  CheckNameRequest,
  Configuration,
  ProposalResponse,
  ProposalsApi,
  ProposalStatus,
  UpdateProposalNameRequest,
} from '../../../generated/proposal';
import {proposalApiBaseLink} from '../../../assets/apiBaseLink';
import {proposalResponseMapper} from './proposalResponseMapper';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {yupResolver} from '@hookform/resolvers/yup';
import {changeNameSchema} from '../../../schemas/changeNameSchema';
import {nameMaxLength} from '../../../schemas/proposalConstants';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import gaEvent from '@scm/components/ga/googleAnalyticsEvent';
import {middleware} from '@scm/authentication/middleware/middlewareConfig';

async function changeProposalName(updateProposalName: UpdateProposalNameRequest) {
  return await new ProposalsApi(
    new Configuration({basePath: proposalApiBaseLink, accessToken: getAccessToken() || '', middleware})
  ).updateProposalName({
    ...updateProposalName,
  });
}

async function handleData(
  updatedProposal: ProposalResponse,
  setLoading: (flag: boolean) => void,
  formatMessage: (obj: {id: string}) => string
) {
  const updateProposalNameRequest: UpdateProposalNameRequest = {
    id: updatedProposal.proposalId as string,
    proposalNameRequest: {
      name: updatedProposal.name || '',
    },
  };

  const proposalName = 'proposal.approved';
  try {
    await changeProposalName(updateProposalNameRequest);
    openToast(
      createMessageString(formatMessage, proposalName, true),
      createMessageString(formatMessage, proposalName),
      ToastType.POSITIVE,
      'spark-icon-check'
    );
    setLoading(true);
  } catch (err) {
    openToast(
      createMessageString(formatMessage, proposalName, true, true),
      createMessageString(formatMessage, proposalName, false, true),
      ToastType.WARNING,
      'spark-icon-alert-triangle'
    );
  }
}

const ProposalChangeNameForm = ({
  handleCloseModal,
  proposal,
  setLoading,
}: {
  handleCloseModal: () => void;
  proposal: ProposalResponse;
  setLoading: (flag: boolean) => void;
}) => {
  const {formatMessage} = useIntl();
  const fieldName = 'name';
  const ariaLevel = 3;
  const [smallSpinnerLoading, isSmallSpinnerLoading] = useState<boolean>(false);
  const [isNameValid, setIsNameValid] = useState<boolean>(false);
  const [isNameAvailable, setIsNameAvailable] = useState<boolean>(false);
  const {status} = proposal;
  const isEditable = status !== ProposalStatus.Rejected && ProposalStatus.Obsolete;
  const regexp = /^[a-zA-Z0-9 :().,@'-]{1,50}$/;

  const {
    handleSubmit,
    control,
    clearErrors,
    setError,
    formState: {errors, isValid, isDirty},
  } = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: {
      [fieldName]: proposal.name,
    },
    resolver: yupResolver(changeNameSchema),
  });

  const setCustomError = () => {
    setError &&
      setError(fieldName, {
        type: 'custom',
        message: formatMessage({id: 'definition.redAppName.notUniqueOrInvalidName'}),
      });
  };

  const showErrorMessage = () => {
    openToast(
      'definition.redAppName.nameVerificationErrorTitle',
      'definition.redAppName.nameVerificationErrorMessage',
      ToastType.WARNING,
      'spark-icon-alert-triangle'
    );
  };

  let typingDelayId: ReturnType<typeof setTimeout>;
  const handleChangeName = async (val: string) => {
    const characterValidationDelay = 50;
    const availableValidationDelay = 1500;

    clearTimeout(typingDelayId);
    setIsNameAvailable(false);

    if (val?.length >= ariaLevel) {
      const hasCorrectCharacters = regexp.test(val);
      if (!hasCorrectCharacters) {
        setIsNameValid(false);
        setTimeout(() => {
          setCustomError();
        }, characterValidationDelay);
        return;
      } else {
        setIsNameValid(true);
      }

      isSmallSpinnerLoading(true);
      typingDelayId = setTimeout(() => {
        const checkNameRequest = {
          name: val,
        };

        fetchNameStatus(checkNameRequest)
          .then(() => {
            setIsNameAvailable(true);
            if (isNameValid) {
              clearErrors([fieldName]);
            }
          })
          .catch(err => {
            setIsNameAvailable(false);
            setCustomError();
            err.response.status !== 400 && showErrorMessage();
          })
          .finally(() => isSmallSpinnerLoading(false));
      }, availableValidationDelay);
    }
  };

  const fetchNameStatus = (checkNameRequest: CheckNameRequest) => {
    return new ProposalsApi(
      new Configuration({
        basePath: proposalApiBaseLink,
        accessToken: getAccessToken() || '',
        middleware: middleware,
      })
    ).checkName(checkNameRequest);
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    handleData(proposalResponseMapper({...proposal, ...data}), setLoading, formatMessage);
    gaEvent('Changed Red App Name');
    handleCloseModal();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="row spark-mar-b-2">
        <div className="col-xs-11">
          <Controller
            name={fieldName}
            control={control}
            render={({field}): ReactElement => (
              <TextInput
                {...field}
                label={formatMessage({id: 'proposalView.redAppName.label'})}
                characterCount
                maxLength={nameMaxLength}
                onChange={(_, val) => {
                  field.onChange(val);
                  if (val !== proposal.name) {
                    handleChangeName(val);
                  }
                }}
                status={errors && errors[field.name as keyof typeof errors] ? MessageStatus.ERROR : undefined}
                statusMessage={
                  errors &&
                  errors[field.name as keyof typeof errors] &&
                  (errors[field.name as keyof typeof errors] as {message: string})?.message
                }
                disabled={!isEditable}
                onKeyPress={e => (!isNameValid || !isNameAvailable) && e.key === 'Enter' && e.preventDefault()}
              />
            )}
          />
        </div>
        {smallSpinnerLoading ? (
          <ProgressIndicator type="indeterminate" size="xs" />
        ) : (
          <Popover
            anchorX="right"
            anchorY="middle"
            className="col-xs-8 col-md-4"
            closeButton
            toggleEl={
              <button
                type="button"
                className="spark-btn--icon spark-icon spark-icon--fill spark-icon-info-circle spark-mar-t-.5"
              />
            }
          >
            <ProposalNameDescription />
          </Popover>
        )}
      </div>
      <ButtonContainer className="spark-flex">
        <Button secondary type="button" onClick={handleCloseModal} size={ButtonSize.SMALL} className="spark-mar-r-1">
          {formatMessage({id: 'common.cancelButton'})}
        </Button>
        <Button
          type="button"
          onClick={handleSubmit(onSubmit)}
          size={ButtonSize.SMALL}
          disabled={!isValid || !isNameValid || !isNameAvailable || !isDirty}
          tabIndex={0}
        >
          {formatMessage({id: 'common.submitButton'})}
        </Button>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  & .row {
    flex-wrap: nowrap;
    overflow: hidden;
  }
`;

export default ProposalChangeNameForm;
