import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Message, Tooltip} from '@sabre/spark-react-core';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import {dateFormat, redAppSupportEmail} from '@scm/components/utils/common';
import styled from 'styled-components';
import {ProposalStatus} from '../../../generated/proposal';
import {format} from 'date-fns';
import {Loading} from '@scm/components';
import HrefElement from '@scm/components/messaging/HrefElement';
import StatusIcon from '@scm/components/statuses/StatusIcon';
import colors from '@scm/components/assets/colors';
import {deviceWidths} from '@scm/components/assets/deviceWidth';

const ProposalViewInfo = ({
  createdAt,
  status,
  reasonRejected,
  setShowProcessSchemeModal,
}: {
  createdAt?: string;
  status?: ProposalStatus;
  reasonRejected?: string;
  setShowProcessSchemeModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const {formatMessage} = useIntl();
  const [statusMessage, setStatusMessage] = useState('');
  const [isLoading, setLoading] = useState(true);
  const isRejected = status === ProposalStatus.Rejected;
  const isApproved = status === ProposalStatus.Approved;

  useEffect(() => {
    const statusValue =
      status === ProposalStatus.InReview ? formatMessage({id: 'proposalView.proposal.status.inReview.value'}) : status;

    setStatusMessage(statusValue ?? '');
    setLoading(false);
  }, [status]);

  const message = (
    <Message
      content={
        formatMessage(
          {
            id: `proposalView.message${(() => {
              if (isRejected) {
                return '.rejected';
              }
              return isApproved ? '.approved' : '';
            })()}.content`,
          },
          {
            strong: text => <strong>{text}</strong>,
            info: text => (reasonRejected ? <p className="spark-pad-t-2">{text}</p> : undefined),
            reasonRejected: reasonRejected,
            email: <HrefElement email={redAppSupportEmail} />,
          }
        ) as string
      }
      role={isRejected ? MessageRole.ALERT : MessageRole.STATUS}
      status={(() => {
        if (isRejected) {
          return MessageStatus.ERROR;
        }
        return isApproved ? MessageStatus.WARNING : MessageStatus.INFO;
      })()}
      title={(() => {
        if (isRejected) {
          return '';
        }
        return isApproved
          ? formatMessage({id: 'proposalView.message.actionRequired.title'})
          : formatMessage({id: 'proposalView.message.actionOnSabre.title'});
      })()}
      type={MessageType.CONTAINER}
      animate
    />
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <MessageWrapperWithPointer onClick={() => setShowProcessSchemeModal(true)}>
            <Tooltip toggleEl={message}>
              <FormattedMessage id="productDetails.statusMessage.showCertificationProcess.label" />
            </Tooltip>
          </MessageWrapperWithPointer>
          <ProductInfo className="spark-pad-1 spark-pad-b-.5 spark-pad-t-1">
            <ul className="spark-list spark-list--no-bullets spark-mar-b-0">
              <InfoListItem className="spark-mar-b-0 spark-mar-t-0">
                <ProductInfoLabel>
                  <FormattedMessage id="proposalView.proposalDate" />
                </ProductInfoLabel>
                {createdAt && format(new Date(createdAt), dateFormat)}
              </InfoListItem>
              <InfoListItem className="spark-mar-b-0 spark-mar-t-0">
                <ProductInfoLabel>
                  <FormattedMessage id="proposalView.proposalStatus" />
                </ProductInfoLabel>
                <StatusCellContent>
                  {status && <StatusIcon statusName={status} />}
                  {status && <FormattedMessage id="proposalView.proposal.status.value" values={{statusMessage}} />}
                </StatusCellContent>
              </InfoListItem>
              {reasonRejected && (
                <InfoListItem className="spark-mar-b-0 spark-mar-t-.5">
                  <ProductInfoLabel>
                    <FormattedMessage id="proposalView.message.rejected" />
                  </ProductInfoLabel>
                  <span>{reasonRejected}</span>
                </InfoListItem>
              )}
            </ul>
          </ProductInfo>
        </>
      )}
    </>
  );
};

const StatusCellContent = styled.span`
  & .spark-message__icon {
    transform: scale(0.5);
  }
`;

const MessageWrapper = styled.div`
  background-color: ${colors.grey100};
  padding: 1rem 2rem 0.5rem;
  margin-bottom: 1rem;

  strong:not(:first-child) {
    color: ${colors.statusInfoGrey};
  }

  & .toast-email-gray > strong {
    color: ${colors.statusInfoGrey} !important;
  }
`;

const MessageWrapperWithPointer = styled(MessageWrapper)`
  cursor: pointer;
`;

const ProductInfo = styled.div`
  background-color: ${colors.grey100};
  justify-content: space-between;
  @media (max-width: ${deviceWidths.sm}px) {
    flex-direction: column;
    & > div {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
`;

const ProductInfoLabel = styled.span`
  &::after {
    content: ':';
    display: inline-block;
    margin-right: 1rem;
  }
`;

const InfoListItem = styled.li`
  padding: 0.15rem 0;

  &:last-of-type {
    transform: translateY(-0.4rem);
  }

  & .spark-icon-tools {
    transform: scale(0.7);
  }
`;

export default ProposalViewInfo;
