import React, {useContext, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {FormProvider, useForm} from 'react-hook-form';
import parse from 'html-react-parser';
import {Loading} from '@scm/components';
import {Button, Checkbox} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {LoadingContainer} from '@scm/components/form/LoadingContainer';
import {StyledH2} from './ContactDetails';
import styled from 'styled-components';
import termsAndConditionsContent from '../../../translations/termsAndConditionsContent';
import {
  AcceptTermsAndConditionsRequest,
  Configuration,
  OnboardDeveloperPartnerRequest,
  OnboardingsApi,
  OnboardingType,
  OnboardRedAppProviderRequest,
} from '../../../generated/onboardings';
import {onboardingsApiBaseLink} from '../../../assets/apiBaseLink';
import {Text} from '@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage';
import {GaContext} from '../../../utils/gaContext';
import {onboardingDeveloperPartnerLatestStepName, onboardingRedAppProviderLatestStepName} from '../../JoinUsContentBox';
import {buildGaActionLabel} from "@scm/components/ga/actions";

export enum EventCategories {
  Header = 'Header',
  Directory = 'Directory',
  PartnerDetails = 'PartnerDetails',
  JoinUs = 'JoinUs',
  Authorization = 'Authorization',
}

export async function postData(request: AcceptTermsAndConditionsRequest) {
  return await new OnboardingsApi(
    new Configuration({
      basePath: onboardingsApiBaseLink,
    })
  ).acceptTermsAndConditions({
    acceptTermsAndConditionsRequest: request,
  });
}

async function handleData(
  successHandler: () => void,
  errorHandler: () => void,
  onboardDeveloperPartnerRequest?: OnboardDeveloperPartnerRequest,
  onboardRedAppProviderRequest?: OnboardRedAppProviderRequest
) {
  const acceptTermsAndConditionsRequest: AcceptTermsAndConditionsRequest = {
    contactDetails: onboardDeveloperPartnerRequest?.contactDetails ?? onboardRedAppProviderRequest!.contactDetails,
    companyInformation:
      onboardDeveloperPartnerRequest?.companyInformation ?? onboardRedAppProviderRequest!.companyInformation,
    sabreCustomerAccountInfo: onboardDeveloperPartnerRequest?.sabreCustomerAccountInfo,
    sabreAccountInfo: onboardRedAppProviderRequest?.sabreAccountInfo,
    type: OnboardingType.DeveloperPartner,
  };

  try {
    await postData(acceptTermsAndConditionsRequest);
    successHandler();
  } catch (e) {
    errorHandler();
  }
}

const TermsAndConditions = ({
  onboardDeveloperPartnerRequest,
  onboardRedAppProviderRequest,
  setCurrentStep,
  acceptTermsAndConditions,
  setAcceptTermsAndConditions,
  isDeveloperPartnerForm,
  currentStep,
}: {
  onboardDeveloperPartnerRequest?: OnboardDeveloperPartnerRequest;
  onboardRedAppProviderRequest?: OnboardRedAppProviderRequest;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  acceptTermsAndConditions: boolean;
  setAcceptTermsAndConditions: React.Dispatch<React.SetStateAction<boolean>>;
  isDeveloperPartnerForm: boolean;
  currentStep: number;
}) => {
  const methods = useForm({mode: 'onChange'});
  const {formatMessage} = useIntl();
  const {logEvent} = useContext(GaContext);
  const [isScrolled, setScrolled] = useState(false);
  const previousStep = () => setCurrentStep(prevState => prevState - 1);

  const [isNotProcessing, setIsNotProcessing] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const formSubmitHandler = async function () {
    if (!acceptTermsAndConditions && isNotProcessing) {
      const successHandler = () => {
        const sessionStorageKey = isDeveloperPartnerForm ? onboardingDeveloperPartnerLatestStepName : onboardingRedAppProviderLatestStepName;
        if ((sessionStorage.getItem(sessionStorageKey) ?? 0) < currentStep) {
          logEvent({
            category: EventCategories.JoinUs,
            action: isDeveloperPartnerForm ? buildGaActionLabel('ga.action.onboarding.movedToPricing', 'ga.tag.partnerNetwork.developerPartner')
              : buildGaActionLabel('ga.action.onboarding.movedToRedAppType', 'ga.tag.partnerNetwork.redAppProvider'),
          });
          sessionStorage.setItem(onboardingDeveloperPartnerLatestStepName, currentStep.toString());
        }
        setCurrentStep(prevState => prevState + 1);
        setAcceptTermsAndConditions(true);
      };

      const errorHandler = () => {
        setCurrentStep(-1);
      };

      await handleData(successHandler, errorHandler, onboardDeveloperPartnerRequest, onboardRedAppProviderRequest);
    }

    if (acceptTermsAndConditions) {
      setCurrentStep(prevState => prevState + 1);
    }

    setIsNotProcessing(false);
  };

  const onScrollHandler = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLDivElement;
    if (target.scrollTop + target.clientHeight + 50 >= target.scrollHeight) {
      setScrolled(true);
    }
  };

  if (!acceptTermsAndConditions && !isNotProcessing) {
    return (
      <LoadingContainer>
        <Loading label={formatMessage({id: 'joinUsPage.form.loadingMessage.message'})} />
      </LoadingContainer>
    );
  }

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <div className="spark-panel">
          <ScrollBarContainer onScroll={onScrollHandler}>
            <div className="spark-panel__content">
              <StyledH2>
                <FormattedMessage id="joinUsPage.form.termsAndConditions.title" />
              </StyledH2>
              <Text>
                <FormattedMessage id="joinUsPage.form.termsAndConditions.description" />
              </Text>
              {[...Object.keys(termsAndConditionsContent)].map((item, index) => (
                <p key={index}>{parse(formatMessage({id: item}))}</p>
              ))}
            </div>
          </ScrollBarContainer>
        </div>
        <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
          <CheckboxContainer>
            <Checkbox
              label={formatMessage({id: 'joinUsPage.form.termsAndConditions.approval.label'})}
              disabled={!isScrolled || acceptTermsAndConditions}
              name="approval"
              checked={acceptTermsAndConditions || termsChecked}
              onChange={evt => setTermsChecked(evt.target.checked)}
            />
          </CheckboxContainer>
          <Button type="button" onClick={previousStep} size={ButtonSize.SMALL} secondary>
            <FormattedMessage id="joinUsPage.form.backButton" />
          </Button>
          <Button disabled={!(acceptTermsAndConditions || termsChecked)} type="submit" size={ButtonSize.SMALL}>
            <FormattedMessage id="joinUsPage.form.button" />
          </Button>
        </nav>
      </form>
    </FormProvider>
  );
};

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  order: -6;
  margin-right: auto;
`;

export const ScrollBarContainer = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
`;
export default TermsAndConditions;
