import React from 'react';
import styled from 'styled-components';
import colors from '../assets/colors';
import Icon from '../icons/Icon';
import statuses, {onboardingStatuses, StatusGroup} from './statuses';

const StatusIcon = ({statusName, isOnboarding}: {statusName: string; isOnboarding?: boolean}) => {
  const statusGroupByStatus = isOnboarding ? onboardingStatuses : statuses;

  const mapStatusNameToMessageType = () => {
    switch (statusGroupByStatus.get(statusName)) {
      case StatusGroup.Success:
        return 'success';
      case StatusGroup.Failed:
        return 'error';
      case StatusGroup.Processing:
        return 'info';
      case StatusGroup.Obsolete:
        return 'info';
      default:
        return 'warning';
    }
  };
  const mapStatusNameToIcon = () => {
    switch (statusGroupByStatus.get(statusName)) {
      case StatusGroup.Success:
        return 'check';
      case StatusGroup.Failed:
        return 'ban';
      case StatusGroup.Obsolete:
        return 'ban';
      case StatusGroup.Processing:
        return 'clock';
      case StatusGroup.Draft:
        return 'pencil';
      default:
        return 'alert-triangle';
    }
  };
  return statusGroupByStatus.get(statusName) === StatusGroup.Obsolete ? (
    <ObsoleteIconContainer className={`spark-message--${mapStatusNameToMessageType()}`}>
      <Icon className={`spark-mar-0 spark-message__icon spark-icon-${mapStatusNameToIcon()}`} aria-label="check" />
    </ObsoleteIconContainer>
  ) : (
    <IconContainer className={`spark-message--${mapStatusNameToMessageType()}`}>
      <Icon className={`spark-mar-0 spark-message__icon spark-icon-${mapStatusNameToIcon()}`} aria-label="check" />
    </IconContainer>
  );
};

export const ObsoleteIconContainer = styled.span`
  & .spark-message__icon {
    transform: scale(0.5);
  }

  & .spark-message__icon {
    background-color: ${colors.statusInfoGrey} !important;
    border: 2px solid ${colors.statusInfoGrey} !important;
  }

  & .spark-message--error {
    color: ${colors.statusInfoGrey} !important;
  }

  & .spark-message__content strong {
    color: ${colors.statusInfoGrey} !important;
  }

  & .spark-icon-pencil {
    background-color: ${colors.grey150};
    border-color: ${colors.grey500};
    color: ${colors.grey500};
  }
`;

export const IconContainer = styled.span`
  & .spark-message__icon {
    transform: scale(0.5);
  }

  & .spark-icon-pencil {
    background-color: ${colors.grey150};
    border-color: ${colors.grey500};
    color: ${colors.grey500};
  }
`;

export default StatusIcon;
