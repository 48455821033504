import React, {useContext} from 'react';
import {ProposalContext} from '../../../ProposalForm';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {TextInput} from '@sabre/spark-react-core';
import {descriptionName} from '../../../ProposalValues';
import {Star, TextEditor} from '@scm/components';
import AdditionalStatement from '../../../../../components/content/AdditionalStatement';
import {textEditorLengthErrorName} from '../../../../proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';

const Description = ({isOnboarding}: {isOnboarding?: boolean}) => {
  const {setValue, watch} = useContext(ProposalContext);
  const descriptionMaxLength = 3000;
  return (
    <>
      <h4 className="spark-mar-b-1 spark-mar-r-2 spark-mar-t-2" aria-level={3}>
        <FormattedMessage id="definition.description.label" />
        <Star />
      </h4>
      <AdditionalStatement id={`definition.description.${isOnboarding ? 'onboarding.' : ''}additionalText`} />
      <TextEditor
        editorState={watch(descriptionName)}
        setValue={setValue}
        valueName={descriptionName}
        maxCharacters={descriptionMaxLength}
        className="spark-mar-r-2"
        errorName={textEditorLengthErrorName}
        isRequired
        isProposal
      />
    </>
  );
};

export const TextArea = styled(TextInput)`
  & textarea {
    min-height: 10rem;
  }
`;

export default Description;
