import React from 'react';
import styled from 'styled-components';
import colors from '../assets/colors';
import {Icon} from '@sabre/spark-react-core';
import {IconStyle} from '@sabre/spark-react-core/types';

interface Props<T> {
  itemsList: Array<T>;
  deleteHandler: (item: T) => void;
  className?: string;
}

export const ItemsList = <T extends Object>({itemsList, deleteHandler, className}: Props<T>) =>
  !!itemsList.length ? (
    <List className={`${className} spark-list--no-bullets`}>
      {itemsList.map((item, index) => (
        <ListItem key={index}>
          <span>{item}</span>
          <span onClick={() => deleteHandler(item)}>
            <Icon name="close" style={IconStyle.FILL} />
          </span>
        </ListItem>
      ))}
    </List>
  ) : null;

const List = styled.ul`
  border: 1px solid ${colors.grey200};
  margin-left: 2rem;
`;

const ListItem = styled.li`
  border-bottom: 1px solid ${colors.grey200};
  margin: 0;
  padding: 1rem 2rem !important;
  display: flex;
  justify-content: space-between;
  overflow-wrap: anywhere;

  &:hover {
    background-color: ${colors.grey150};
  }

  &:last-of-type {
    border-bottom: none;
  }

  & .spark-icon {
    transform: scale(0.6);
  }

  & .spark-icon:hover {
    cursor: pointer;
  }
`;
