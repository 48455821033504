import React, {useContext, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {FormProvider, Resolver, SubmitHandler, useForm, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import useYupValidationResolver, {getFieldMaxLength, isFieldRequired} from '../../components/useYupValidationResolver';
import {Star} from '@scm/components';
import colors from '@scm/components/assets/colors';
import {Fields} from '../../components/EndMessage';
import styled from 'styled-components';
import {Button, RadioButton, RadioButtonGroup, TextInput} from '@sabre/spark-react-core';
import SparkInput, {Optional} from '@scm/components/form/SparkInput';
import SparkDropDown from '@scm/components/form/SparkDropDown';
import countries from '@scm/components/assets/pnCountries';
import {ButtonSize, MessageStatus} from '@sabre/spark-react-core/types';
import {Confirmation} from '@scm/proposal/pages/proposalForm/steps/serviceAndSecurity/ServiceAndSecurityValues';
import {
  onboardingEprValueName,
  onboardingPccName,
  onboardingPccValueName,
  RadioButtonNamesAndValues,
} from './RedAppProviderFormContent';
import {
  onboardingProviderTypeName,
  onboardingRedAppProviderLatestStepName,
  ProviderTypeValue
} from '../../JoinUsContentBox';
import {OnboardRedAppProviderRequest} from '../../../generated/onboardings';
import schema from '../../../onboarding-schema/onboardRedAppProvider.schema';
import {Text} from '@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage';
import Legend from '@scm/components/accessibility/Legend';
import {
  EPR_LONG_MESSAGE,
  EPR_MAX_LENGTH,
  EPR_REGEX,
  PCC_LONG_MESSAGE,
  PCC_MAX_LENGTH,
  PCC_REGEX,
} from '@scm/components/pccEpr/pccEprSchema';
import {EventCategories} from '../developerPartner/TermsAndConditions';
import {GaContext} from '../../../utils/gaContext';
import {buildGaActionLabel} from "@scm/components/ga/actions";

const ContactDetails = ({
  saveHandler,
  initValue,
  setCurrentStep,
  watch,
  setValue,
  setProviderTypeValue,
  currentStep,
}: {
  saveHandler: React.Dispatch<React.SetStateAction<OnboardRedAppProviderRequest>>;
  initValue: OnboardRedAppProviderRequest;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  watch: UseFormWatch<RadioButtonNamesAndValues>;
  setValue: UseFormSetValue<RadioButtonNamesAndValues>;
  setProviderTypeValue: UseFormSetValue<ProviderTypeValue>;
  currentStep: number;
}) => {
  const {formatMessage} = useIntl();
  const resolver = useYupValidationResolver(schema.pick(['contactDetails', 'companyInformation', 'sabreAccountInfo']));
  const methods = useForm<OnboardRedAppProviderRequest>({
    mode: 'onChange',
    resolver: resolver as Resolver<OnboardRedAppProviderRequest>,
  });
  const {logEvent} = useContext(GaContext);
  const formSubmitHandler: SubmitHandler<OnboardRedAppProviderRequest> = (data: OnboardRedAppProviderRequest) => {
    saveHandler(data);
    setCurrentStep(prevState => prevState + 1);
    if ((sessionStorage.getItem(onboardingRedAppProviderLatestStepName) ?? 0) < currentStep) {
      logEvent({category: EventCategories.JoinUs, action: buildGaActionLabel('ga.action.onboarding.movedToTandC', 'ga.tag.partnerNetwork.redAppProvider')});
      sessionStorage.setItem(onboardingRedAppProviderLatestStepName, currentStep.toString());
    }
  };

  const [isPccTouched, setIsPccTouched] = useState(false);

  const isPccRequired = watch(onboardingPccName) === Confirmation.Yes;
  const isPccChecked = !!watch(onboardingPccName);

  const isPccGroupValid = isPccChecked && (!isPccRequired || PCC_REGEX.test(watch(onboardingPccValueName) || ''));
  const isEprGroupValid =
    isPccChecked && (!watch(onboardingEprValueName)?.length || EPR_REGEX.test(watch(onboardingEprValueName) || ''));

  const shouldBeDisabled = !methods.formState.isValid || !isPccGroupValid || !isEprGroupValid;

  const previousStep = () => {
    setCurrentStep(prevState => prevState - 1);
    setProviderTypeValue(onboardingProviderTypeName, undefined);
  };

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <div className="spark-panel">
          <div className="spark-panel__content">
            <StyledH2>
              <FormattedMessage id="joinUsPage.form.contactDetails.title" />
            </StyledH2>
            <Text>
              <FormattedMessage id="contactDetails.provider.description" />
            </Text>
            <Fields>
              <StyledH3>
                <FormattedMessage id="contactDetails.contactDetails.title" />
              </StyledH3>
              <SparkInput
                name="contactDetails.firstName"
                value={initValue.contactDetails.firstName}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.firstName.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.firstName.placeholder'})}
                errorMessage={methods.formState.errors.contactDetails?.firstName?.message}
                isRequired={isFieldRequired('contactDetails.firstName', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('contactDetails.firstName', schema)}
              />
              <SparkInput
                name="contactDetails.lastName"
                value={initValue.contactDetails.lastName}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.lastName.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.lastName.placeholder'})}
                errorMessage={methods.formState.errors.contactDetails?.lastName?.message}
                isRequired={isFieldRequired('contactDetails.lastName', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('contactDetails.lastName', schema)}
              />
              <SparkInput
                name="contactDetails.titleInCompany"
                value={initValue.contactDetails.titleInCompany}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.companyTitle.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.companyTitle.placeholder'})}
                errorMessage={methods.formState.errors.contactDetails?.titleInCompany?.message}
                isRequired={isFieldRequired('contactDetails.titleInCompany', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('contactDetails.titleInCompany', schema)}
              />
              <SparkInput
                name="contactDetails.email"
                value={initValue.contactDetails.email}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.email.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.email.placeholder'})}
                errorMessage={methods.formState.errors.contactDetails?.email?.message}
                isRequired={isFieldRequired('contactDetails.email', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('contactDetails.email', schema)}
              />
              <SparkInput
                name="contactDetails.phone"
                value={initValue.contactDetails.phone}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.phone.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.phone.placeholder'})}
                errorMessage={methods.formState.errors.contactDetails?.phone?.message}
                isRequired={isFieldRequired('contactDetails.phone', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('contactDetails.phone', schema)}
              />
              <StyledH3>
                <FormattedMessage id="contactDetails.companyInformation.title" />
              </StyledH3>
              <SparkInput
                name="companyInformation.name"
                value={initValue.companyInformation.name}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.fullCompanyName.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.fullCompanyName.placeholder'})}
                errorMessage={methods.formState.errors.companyInformation?.name?.message}
                isRequired={isFieldRequired('companyInformation.name', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.name', schema)}
              />
              <SparkInput
                name="companyInformation.website"
                value={initValue.companyInformation.website}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.url.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.url.placeholder'})}
                errorMessage={formatMessage({id: 'validation.fieldDoesNotMatchPattern.website'})}
                isRequired={isFieldRequired('companyInformation.website', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.website', schema)}
              />
              <SparkInput
                name="companyInformation.businessProfile"
                value={initValue.companyInformation.businessProfile}
                label={formatMessage({id: 'contactDetails.businessProfile.label'})}
                placeholder={formatMessage({id: 'contactDetails.businessProfile.placeholder'})}
                errorMessage={methods.formState.errors.companyInformation?.businessProfile?.message}
                isRequired={isFieldRequired('companyInformation.businessProfile', schema)}
                hasCharacterCounter
                isMultiline
                maxLength={getFieldMaxLength('companyInformation.businessProfile', schema)}
              />
              <StyledH3>
                <FormattedMessage id="joinUsPage.form.companyLocation.title" />
              </StyledH3>
              <SparkDropDown
                name="companyInformation.locationCountry"
                defaultValue={countries.DEFAULT}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.countryLocated.label'})}
                errorMessage={methods.formState.errors.companyInformation?.locationCountry?.message}
                options={countries}
                isRequired={isFieldRequired('companyInformation.locationCountry', schema)}
              />
              <SparkInput
                name="companyInformation.locationProvince"
                value={initValue.companyInformation.locationProvince}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.provinceLocated.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.provinceLocated.placeholder'})}
                errorMessage={methods.formState.errors.companyInformation?.locationProvince?.message}
                isRequired={isFieldRequired('companyInformation.locationProvince', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.locationProvince', schema)}
              />
              <StyledH4 className="spark-mar-t-2">
                <FormattedMessage id="contactDetails.companyInformation.address.label" />
              </StyledH4>
              <SparkInput
                name="companyInformation.address.line1"
                value={initValue.companyInformation.address.line1}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.addressLine1.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.addressLine1.placeholder'})}
                errorMessage={(methods.formState.errors as any).companyInformation?.address?.line1?.message}
                isRequired={isFieldRequired('companyInformation.address.line1', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.address.line1', schema)}
              />
              <SparkInput
                name="companyInformation.address.line2"
                value={initValue.companyInformation.address.line2}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.addressLine2.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.addressLine2.placeholder'})}
                errorMessage={(methods.formState.errors as any).companyInformation?.address?.line2?.message}
                isRequired={isFieldRequired('companyInformation.address.line2', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.address.line2', schema)}
              />
              <SparkInput
                name="companyInformation.address.city"
                value={initValue.companyInformation.address.city}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.city.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.city.placeholder'})}
                errorMessage={(methods.formState.errors as any).companyInformation?.address?.city?.message}
                isRequired={isFieldRequired('companyInformation.address.city', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.address.city', schema)}
              />
              <SparkInput
                name="companyInformation.address.province"
                value={initValue.companyInformation.address.province}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.stateProvinceName.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.stateProvinceName.placeholder'})}
                errorMessage={(methods.formState.errors as any)?.companyInformation?.address?.province?.message}
                isRequired={isFieldRequired('companyInformation.address.province', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.address.province', schema)}
              />
              <SparkInput
                name="companyInformation.address.postalCode"
                value={initValue.companyInformation.address.postalCode}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.postalCode.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.postalCode.placeholder'})}
                errorMessage={(methods.formState.errors as any)?.companyInformation?.address?.postalCode?.message}
                isRequired={isFieldRequired('companyInformation.address.postalCode', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.address.postalCode', schema)}
              />
              <SparkDropDown
                name="companyInformation.address.country"
                defaultValue={countries.DEFAULT}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.country.label'})}
                errorMessage={(methods.formState.errors as any)?.companyInformation?.address?.country?.message}
                options={countries}
                isRequired={isFieldRequired('companyInformation.address.country', schema)}
              />
              <StyledH3>
                <FormattedMessage id="contactDetails.additionalInfo.title" />
              </StyledH3>
              <div className="spark-mar-b-2 spark-small">
                <FormattedMessage id="contactDetails.additionalInfo.description" />
              </div>
              <StyledH4>
                <FormattedMessage id="contactDetails.pccEpr.label" />
                <Star starColor={colors.red} />
              </StyledH4>
              <RadioButtonGroup
                name={onboardingPccName}
                value={watch(onboardingPccName)}
                onChange={event => {
                  const value = event.target.value;
                  setValue(onboardingPccName, value as Confirmation, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  });

                  if (value === Confirmation.No) {
                    setValue(onboardingPccValueName, undefined, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });

                    setValue(onboardingEprValueName, undefined, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });
                  }
                }}
              >
                <Legend text={formatMessage({id: 'contactDetails.pccEpr.label'})} />
                <RadioButton
                  className="col-xs-12 col-md-2"
                  label={Confirmation.Yes}
                  value={Confirmation.Yes}
                  aria-label={`${formatMessage({id: 'contactDetails.pccEpr.label'})} - ${Confirmation.Yes}`}
                />
                <RadioButton
                  className="col-xs-12 col-md-2 spark-mar-b-2"
                  label={Confirmation.No}
                  value={Confirmation.No}
                  aria-label={`${formatMessage({id: 'contactDetails.pccEpr.label'})} - ${Confirmation.No}`}
                  data-testid="PCC"
                />
                {watch(onboardingPccName) === Confirmation.Yes && (
                  <div className="spark-mar-t-2">
                    <AdditionalText className="spark-mar-b-0 spark-small">
                      {formatMessage({
                        id: 'contactDetails.pcc.note',
                      })}
                    </AdditionalText>
                    <div className="col-xs-4 spark-mar-b-2">
                      <TextInput
                        label={
                          (
                            <>
                              {formatMessage({id: 'contactDetails.pcc.label'})}
                              <Star />
                            </>
                          ) as unknown as string
                        }
                        name="sabreAccountInfo.pcc"
                        value={watch(onboardingPccValueName)}
                        onChange={(_, value) => {
                          setValue(onboardingPccValueName, value, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          });

                          if (!isPccTouched) {
                            setIsPccTouched(true);
                          }
                        }}
                        onInput={e => (e.currentTarget.value = e.currentTarget.value.toUpperCase())}
                        maxLength={PCC_MAX_LENGTH}
                        characterCount
                        width="col-xs-4"
                        status={!isPccGroupValid && isPccTouched ? MessageStatus.ERROR : undefined}
                        statusMessage={PCC_LONG_MESSAGE}
                      />
                    </div>
                  </div>
                )}
              </RadioButtonGroup>
              {watch(onboardingPccName) === Confirmation.Yes && (
                <div className="spark-mar-t-1 ">
                  <AdditionalText className="spark-mar-b-0 spark-p-l-0 spark-small">
                    {formatMessage({
                      id: 'contactDetails.epr.note',
                    })}
                  </AdditionalText>
                  <div className="col-xs-4">
                    <TextInput
                      label={
                        (
                          <>
                            {formatMessage({id: 'contactDetails.epr.placeholder'})}
                            <Optional />
                          </>
                        ) as unknown as string
                      }
                      name="sabreAccountInfo.epr"
                      value={watch(onboardingEprValueName)}
                      onChange={(_, value) =>
                        setValue(onboardingEprValueName, value.toUpperCase(), {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }
                      onInput={e => (e.currentTarget.value = e.currentTarget.value.toUpperCase())}
                      maxLength={EPR_MAX_LENGTH}
                      characterCount
                      status={!isEprGroupValid ? MessageStatus.ERROR : undefined}
                      statusMessage={EPR_LONG_MESSAGE}
                    />
                  </div>
                </div>
              )}
            </Fields>
          </div>
        </div>
        <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
          <Button type="button" onClick={previousStep} size={ButtonSize.SMALL} secondary>
            <FormattedMessage id="joinUsPage.form.backButton" />
          </Button>
          <Button type="submit" disabled={shouldBeDisabled} size={ButtonSize.SMALL}>
            <FormattedMessage id="joinUsPage.form.button" />
          </Button>
        </nav>
      </form>
    </FormProvider>
  );
};

export const StyledH2 = styled.h2`
  font-size: 2.08rem;
  font-weight: 400;
  line-height: 3rem;
`;

export const StyledH3 = styled.h3`
  font-size: 1.58rem;
  line-height: 2rem;
  padding-top: 20px;
`;

export const StyledH4 = styled.h4`
  font-size: 1.38rem;
  font-weight: 300;
  line-height: 1rem;
  padding-bottom: 10px;
`;

export const AdditionalText = styled.p`
  transform: translateY(-1rem);
`;

export default ContactDetails;
