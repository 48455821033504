import {Quill} from 'quill';

export const encodeHtmlEntities = (str: string) => {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;');
};

export const countEncodedTags = (encodedHtmlText: string) => {
  const encodedTagPattern = /&[a-zA-Z]+;/g;
  const matches = encodedHtmlText.match(encodedTagPattern);
  return matches ? matches.reduce((acc, tag) => acc + tag.length, 0) : 0;
};

export const textUtils = (editor: Quill) => {
  const text = editor.getText();
  const encodedText = encodeHtmlEntities(text);
  return encodedText.length;
};
