import {createContext} from 'react';
import {logEvent} from '../joinUs/form/redAppProvider/RedAppType';

interface GaFeatures {
  logEvent: typeof logEvent;
}

const initialValues: GaFeatures = {
  logEvent,
};
export const GaContext = createContext<GaFeatures>(initialValues);
