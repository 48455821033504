import common from './en/common';
import error from './en/error';
import footer from './en/footer';
import header from './en/header';
import homePage from './en/homePage';
import partnerDirectoryPage from './en/partnerDirectoryPage';
import partnerPage from './en/partnerPage';
import joinUsPage from './en/joinUsPage';
import partnerContact from 'translations/en/partnerContact';
import benefits from 'translations/en/benefits';
import tiers from 'translations/en/tiers';
import termsAndConditionsContent from './en/termsAndConditionsContent';
import validation from './en/validation';
import {authentication} from '@scm/authentication/translations/authentication';
import proposal from '@scm/proposal/translations/proposal';
import {onboardingContent} from '@scm/onboarding/translations/onboardingContent';
import {pricingDetails} from '@scm/onboarding/translations/pricingDetails';
import {publicStandard} from '@scm/components/translations/infoTexts/publicStandard';
import {privateData} from '@scm/components/translations/infoTexts/privateData';
import {privateStandard} from '@scm/components/translations/infoTexts/privateStandard';
import {onboardingInfo} from '@scm/components/translations/onboardingInfo';
import processScheme from '@scm/components/translations/processScheme';
import termsAndConditions from './en/termsAndConditions';

const translations = {
  ...authentication,
  ...common,
  ...error,
  ...footer,
  ...header,
  ...homePage,
  ...partnerDirectoryPage,
  ...partnerPage,
  ...partnerContact,
  ...benefits,
  ...tiers,
  ...joinUsPage,
  ...termsAndConditionsContent,
  ...validation,
  ...onboardingContent,
  ...onboardingInfo,
  ...publicStandard,
  ...privateData,
  ...privateStandard,
  ...proposal,
  ...pricingDetails,
  ...termsAndConditions,
  ...processScheme,
};

export default translations;
