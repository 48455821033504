import React, {MutableRefObject, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import ProposalInsuranceText from '../../../components/content/ProposalInsuranceText';
import {ProposalContainer} from '../ProposalTypePage';
import {Button, Checkbox} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {ProposalContext} from '../ProposalForm';
import {Controller} from 'react-hook-form';
import PageHeader from '../../../components/content/PageHeader';
import {termsAndConditionName} from '../ProposalValues';
import ButtonWithTooltip from '@scm/components/form/ButtonWithTooltip';
import AdditionalStatement from '../../../components/content/AdditionalStatement';
import gaEvent from '@scm/components/ga/googleAnalyticsEvent';
import {proposalPublicRedAppFormStepNumberName} from "../PagesMap";
import {onboardingRedAppProviderLatestStepName} from '@scm/onboarding/joinUs/JoinUsContentBox';
import {buildGaActionLabel} from "@scm/components/ga/actions";

const InsurancePage = ({currentStep}: {currentStep: number}) => {
  const {handleContinue, handleCancel, control, watch, isOnboarding} = useContext(ProposalContext);
  const {formatMessage} = useIntl();
  const ref: any = useRef<HTMLLIElement>();
  const onScreen: boolean = useOnScreen<HTMLLIElement>(ref, '-150px');

  const handleInsuranceContinue = () => {
    const formType = isOnboarding ? 'ga.tag.partnerNetwork.redAppProvider' : 'ga.tag.adminCentre.redAppProposal';
    const sessionStorageKey = isOnboarding ? onboardingRedAppProviderLatestStepName : proposalPublicRedAppFormStepNumberName;
    handleContinue(false);
    if ((sessionStorage.getItem(sessionStorageKey) ?? 0) < currentStep) {
      gaEvent(buildGaActionLabel('ga.action.proposal.acceptedInsuranceRequirements' , formType, 'ga.tag.redAppType.public'));
      sessionStorage.setItem(proposalPublicRedAppFormStepNumberName, currentStep.toString());
    }
  };

  const backButton = (
    <Button secondary size={ButtonSize.SMALL} onClick={handleCancel} className="spark-mar-r-1">
      <FormattedMessage id="common.backButton" />
    </Button>
  );

  const checkbox = (
    <Controller
      name={termsAndConditionName}
      control={control}
      render={({field: {onChange, onBlur, value}}): ReactElement => (
        <TermsAndConditionCheckbox
          onBlur={onBlur}
          onChange={onChange}
          checked={value}
          label={formatMessage({id: 'insurance.termsAndCondition.label'})}
          className="spark-mar-r-1"
          disabled={!watch(termsAndConditionName) && !onScreen}
          data-testid="Approve"
        />
      )}
    />
  );

  return (
    <>
      <ProposalContainer className="spark-panel spark-pad-l-2">
        <ScrollBarContainer tabIndex={0} role="article" data-testid="SCROLL">
          <PageHeader id="proposal.insurance.title" />
          <AdditionalStatement id="proposal.insurance.additionalTitle" />
          <ProposalInsuranceText liRef={ref} />
        </ScrollBarContainer>
      </ProposalContainer>
      <ButtonContainerInsurance className="spark-mar-t-1 spark-flex">
        {isOnboarding ? checkbox : backButton}
        <ButtonContainer className="spark-flex">
          {isOnboarding ? backButton : checkbox}
          <ButtonWithTooltip
            handleContinue={handleInsuranceContinue}
            isValid={!!watch(termsAndConditionName)}
            messageId="insurance.requiredFields.message"
          />
        </ButtonContainer>
      </ButtonContainerInsurance>
    </>
  );
};

function useOnScreen<T extends Element>(ref: MutableRefObject<T>, rootMargin: string = '0px'): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      ref.current && observer.unobserve(ref.current);
    };
  }, []);
  return isIntersecting;
}

export const ButtonContainerInsurance = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  flex-wrap: wrap;
`;

const ScrollBarContainer = styled.div`
  max-height: 60vh;
  overflow-y: scroll;
`;

const TermsAndConditionCheckbox = styled(Checkbox)`
  transform: translateY(0.5rem);
`;

export default InsurancePage;
