import jwtDecode from 'jwt-decode';

export const ACCESS_TOKEN_KEY = 'accessToken';

const getAccessToken = () => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  return token ? String(token) : token;
};
export const areTermsAndConditionsAccepted = () =>
  getAccessToken()
    ? jwtDecode<{terms_and_conditions_accepted: boolean}>(getAccessToken() || '')
    : {terms_and_conditions_accepted: false};
