import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Modal} from '@sabre/spark-react-core';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  Configuration,
  TermsAndConditionsApi,
  TermsAndConditionsResponse,
} from '@scm/authentication/generated/authorization';
import {authorizationApiBaseLink} from '@scm/authentication/assets/apiLink';
import {getAccessToken, setToken} from '@scm/authentication/utils/authentication';
import styled from 'styled-components';
import {LoadingContainer} from '@scm/authentication/components/Login';
import {Loading} from '@scm/components';
import colors from '@scm/components/assets/colors';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {middleware} from '@scm/authentication/middleware/middlewareConfig';

export const TermsAndConditionsModal = ({
  open,
  handleClose,
  isReadOnly = false,
}: {
  open: boolean;
  handleClose: () => void;
  isReadOnly?: boolean;
}) => {
  const [termsAndConditions, setTermsAndConditions] = useState<TermsAndConditionsResponse>({});
  const [isLoading, setLoading] = useState(true);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const {formatMessage} = useIntl();
  const ref = useRef<HTMLLIElement>();
  const {version, body} = termsAndConditions;

  const termsAndConditionErrorMessage = 'termsAndCondition.error';

  const fetchLatestTermsAndConditions = async () =>
    new TermsAndConditionsApi(
      new Configuration({
        basePath: authorizationApiBaseLink,
      })
    ).getLatestTermsAndConditions();

  const acceptTermsAndConditions = async () =>
    new TermsAndConditionsApi(
      new Configuration({
        basePath: authorizationApiBaseLink,
        accessToken: getAccessToken() as string,
        middleware,
      })
    ).acceptTermsAndConditions({version: version as string});

  const acceptTermsAndConditionsHandler = (success?: () => {}) => {
    acceptTermsAndConditions()
      .then(response => {
        setToken(response.jwtAccessToken);
        success?.();
        window.location.reload();
      })
      .catch(_ =>
        openToast(
          createMessageString(formatMessage, termsAndConditionErrorMessage, true, true),
          createMessageString(formatMessage, termsAndConditionErrorMessage, false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      );
  };

  useEffect(() => {
    if (open) {
      fetchLatestTermsAndConditions()
        .then(terms => setTermsAndConditions(terms))
        .catch(() => {
          //do nothing
        })
        .finally(() => setLoading(false));
    }
  }, [open, setTermsAndConditions, setLoading]);

  const FooterContent = (): ReactElement => (
    <nav className="spark-btn-group spark-mar-r-2">
      {isReadOnly ? (
        <Button className="spark-btn-group-primary" onClick={handleClose} size={ButtonSize.SMALL}>
          <FormattedMessage id="common.button.close" />
        </Button>
      ) : (
        <Button
          progress
          className="spark-btn-group-primary"
          onClick={(_, success): void => acceptTermsAndConditionsHandler(success)}
          size={ButtonSize.SMALL}
          disabled={!(isRead && isAccepted)}
          tabIndex={0}
        >
          <FormattedMessage id="common.submitButton" />
        </Button>
      )}
    </nav>
  );

  const onScroll = () => {
    if (ref.current) {
      const {scrollTop, scrollHeight, clientHeight} = ref.current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        setIsRead(true);
      }
    }
  };

  return (
    <ModalWrapper
      onClose={handleClose}
      contentClassName="col-md-9"
      open={open}
      modalFooter={<FooterContent />}
      disableEscapeKeyDown={!isReadOnly}
      isReadOnly={isReadOnly}
      ariaLabel="Terms and Conditions content"
    >
      {isLoading ? (
        <LoadingContainer>
          <Loading label={formatMessage({id: 'common.data.loading'})} />
        </LoadingContainer>
      ) : (
        <>
          <ScrollBarContainer data-testid="termsScrollbarContainer" onScroll={onScroll} ref={ref}>
            <ModalText dangerouslySetInnerHTML={{__html: body}} />
          </ScrollBarContainer>
          <AdditionalInformationContainer className="spark-flex row" isReadOnly={isReadOnly}>
            {!isReadOnly && (
              <Checkbox
                data-testid="termsAndConditionsCheckbox"
                onChange={() => setIsAccepted(!isAccepted)}
                checked={isAccepted}
                label={formatMessage({id: 'termsAndConditions.checkbox.value'})}
                className="spark-mar-r-1"
                disabled={!isRead}
                tabIndex={0}
              />
            )}
            <FormattedMessage id="termsAndConditions.currentVersion" values={{currentVersion: version}} />
          </AdditionalInformationContainer>
        </>
      )}
    </ModalWrapper>
  );
};

export const ModalWrapper = styled(Modal)<{isReadOnly: boolean}>`
  .spark-modal__close {
    ${props => (props.isReadOnly ? '' : 'display: none !important')};
  }
`;

export const ScrollBarContainer = styled.div`
  max-height: 70vh;
  overflow-y: scroll;
  border: 1px solid ${colors.grey300};
  padding: 2rem;
  margin: 2rem;
`;

export const ModalText = styled.div`
  white-space: normal;
  color: ${colors.black};

  .additional-information {
    color: ${colors.grey700} !important;
    font-weight: bold;
  }

  h2,
  h4 {
    font-weight: bold;
  }

  ol > li {
    list-style-type: lower-alpha !important;
  }

  ul > li {
    list-style: none;
  }
`;

export const AdditionalInformationContainer = styled(ModalText)<{isReadOnly: boolean}>`
  flex-wrap: nowrap;
  justify-content: ${props => (props.isReadOnly ? 'flex-end' : 'space-between')};
  align-items: center;
  margin: 0 2rem;
  line-height: normal;
`;
