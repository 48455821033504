import AppInfoDataValues, {
  additionalInformationDescriptionName,
  categoryName,
  featuresDescriptionName,
  keyBenefitsDescriptionName,
  patchReleaseNotesDescriptionName,
  productIconName,
  productName,
  productScreenshotsName,
  proposalDateName,
  redAppIdName,
  referenceGuideName,
  respondMessageName,
  sellerConfirmationRequiredName,
  statusName,
  storefrontDescriptionName,
  storefrontShortDescriptionName,
  supportDescriptionName,
  termsAndConditionsDescriptionName,
  typeName,
  ytLinkName,
} from '../interfaces/AppInfoDataValues';
import {Confirmation} from '@scm/proposal/pages/proposalForm/steps/serviceAndSecurity/ServiceAndSecurityValues';
const additionalFileName = 'additionalFile';

export const appInfoInitialValues: AppInfoDataValues = {
  [redAppIdName]: '',
  [productName]: '',
  [productIconName]: '',
  [typeName]: '',
  [proposalDateName]: '',
  [statusName]: '',
  [categoryName]: [],
  [sellerConfirmationRequiredName]: Confirmation.No,
  [storefrontDescriptionName]: '',
  [storefrontShortDescriptionName]: '',
  [featuresDescriptionName]: '',
  [keyBenefitsDescriptionName]: '',
  [supportDescriptionName]: '',
  [additionalInformationDescriptionName]: '',
  [termsAndConditionsDescriptionName]: '',
  [ytLinkName]: '',
  [patchReleaseNotesDescriptionName]: '',
  [productScreenshotsName]: [],
  [referenceGuideName]: [],
  [additionalFileName]: [],
  [respondMessageName]: '',
};
