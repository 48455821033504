const partnerPage = {
  'partnerPage.title': '<bold>{partnerName}</bold>',
  'partnerPage.card.companyName': 'Company Name:',
  'partnerPage.card.contactName': 'Contact Name:',
  'partnerPage.card.phone': 'Phone Number:',
  'partnerPage.card.email': 'Email Address:',
  'partnerPage.card.emails': 'Email Addresses:',
  'partnerPage.card.website': 'Company URL:',
  'partnerPage.card.companyAddress': 'Company Address:',
  'partnerPage.card.offerings': 'Product Offerings:',
  'partnerPage.card.availability': 'Developer Partner Availability:',
  'partnerPage.card.additionalComments': 'Specific Countries/Regions:',
  'partnerPage.card.approvalDate': 'Developer Partner since:',
  'partnerPage.description.title': 'About {partnerName}',
  'partnerPage.description.contactUs': 'Contact Us',
  'partnerPage.description.contactUsMessage':
    'Please use the email address displayed on the Developer Partner information, if your email message box does not open.',
  'partnerPage.products.title': '{partnerName} Products',
  'partner.products.viewProduct': 'View product',
  'partnerPage.testimonials.title': 'What Are People Saying About This Developer Partner?',
};

export default partnerPage;
