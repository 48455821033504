import {
  CcrzEPageLabelCSObject,
  CcrzEProductCSObjectProvisioningTypeCEnum,
  CcrzEProductCSObjectSabrePricingTypeCEnum,
} from '../../../generated/product';
import {Confirmation, ProductMedia} from '../productDetailsUtils';
import {ImageFile} from '@scm/components/files/FileUpload';

export const redAppIdName = 'redAppId';
export const productName = 'productName';
export const productIconName = 'productIcon';
export const productIconBlobName = 'productIconBlob';
export const typeName = 'type';
export const mainPluginIdName = 'mainPluginIdName';
export const proposalDateName = 'proposalDate';
export const statusName = 'status';
export const categoryName = 'category';
export const sellerConfirmationRequiredName = 'sellerConfirmationRequired';
export const storefrontDescriptionName = 'storefrontDescription';
export const storefrontShortDescriptionName = 'storefrontShortDescription';
export const featuresDescriptionName = 'featuresDescription';
export const keyBenefitsDescriptionName = 'keyBenefitsDescription';
export const supportDescriptionName = 'supportDescription';
export const additionalInformationDescriptionName = 'additionalInformationDescription';
export const termsAndConditionsDescriptionName = 'termsAndConditionsDescription';
export const ytLinkName = 'ytLink';
export const patchReleaseNotesDescriptionName = 'patchReleaseNotesDescription';
export const productScreenshotsName = 'productScreenshots';
export const referenceGuideName = 'referenceGuide';
export const additionalFileName = 'additionalFile';
export const referenceGuideUrl = 'referenceGuideUrl';
export const respondMessageName = 'respondMessage';
export const isPaidName = 'isPaid';
export const hideProductName = 'hideProduct';
export const blockOrderingName = 'blockOrdering';
export const blockOrderingDescriptionName = 'blockOrderingDescription';
export const provisioningTypeName = 'provisioningType';

export default interface AppInfoDataValues {
  [redAppIdName]: string;
  [productName]: string;
  [productIconName]: string;
  [productIconBlobName]: Blob;
  [typeName]: string;
  [mainPluginIdName]: string;
  [proposalDateName]: string;
  [statusName]: string;
  [categoryName]: Array<string>;
  [sellerConfirmationRequiredName]: string;
  [storefrontDescriptionName]: string;
  [storefrontShortDescriptionName]: string;
  [featuresDescriptionName]: string;
  [keyBenefitsDescriptionName]: string;
  [supportDescriptionName]: string;
  [additionalInformationDescriptionName]: string;
  [termsAndConditionsDescriptionName]: string;
  [ytLinkName]: string;
  [patchReleaseNotesDescriptionName]: string;
  [productScreenshotsName]: Array<ProductMedia | ImageFile>;
  [referenceGuideName]: File[];
  [referenceGuideUrl]: string;
  [additionalFileName]: File[];
  [respondMessageName]: string;
  [isPaidName]?: CcrzEProductCSObjectSabrePricingTypeCEnum | Confirmation;
  [hideProductName]: string;
  [blockOrderingName]: string;
  [blockOrderingDescriptionName]?: CcrzEPageLabelCSObject;
  [provisioningTypeName]: CcrzEProductCSObjectProvisioningTypeCEnum;
}
